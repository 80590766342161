import {
  ActionIcon,
  Box,
  Button,
  Center,
  Collapse,
  CopyButton,
  createStyles,
  Divider,
  Grid,
  Group,
  Loader,
  Stack,
  Text,
  Tooltip,
} from '@asuikit/core'
import { useDisclosure } from '@asuikit/hooks'
import {Percent, Token} from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { useRequest } from 'ahooks'
import BigNumberJS from 'bignumber.js'
import { useCallback, useContext, useMemo, useState } from 'react'
import { GoTriangleDown, GoTriangleUp } from 'react-icons/go'
import { PiCopyLight } from 'react-icons/pi'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { LPStakeRewardModel } from 'service/pools.api.d'
import { shortenAddress } from 'utilities/src/addresses'
import V2RemoveLiquidityModal from '../../components/detrade/pool/V2RemoveLiquidity'
import { DoubleCurrencyLogo } from '../../components/DoubleLogo'
import TransactionConfirmationModal from '../../components/TransactionConfirmationModal'
import { getApiChainParam } from '../../constants/chains'
import { useLargeThan } from '../../hooks/detrade/useWidthQuery'
import { useCurrency } from '../../hooks/Tokens'
import useCheckTargetChain from '../../hooks/useCheckTargetChain'
import { useTotalSupply } from '../../hooks/useTotalSupply'
import { useV2Pair } from '../../hooks/useV2Pairs'
import { getMyV2LpReward } from '../../service/pools.api'
import { useTokenBalance } from '../../state/connection/hooks'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { TransactionInfo, TransactionType } from '../../state/transactions/types'
import { sleep } from '../../utils'
import { currencyId } from '../../utils/currencyId'
import { mantissaNumber } from '../../utils/detrade/number'
import { useFormatter } from '../../utils/formatNumbers'
import { handleErrorMessage } from '../../utils/handleErrorMessage'
import { didUserReject } from '../../utils/swapErrorToUserReadableMessage'
import { unwrappedToken } from '../../utils/unwrappedToken'
import { MyLPContext } from './index'
import ModalV2PoolUnstake from './ModalV2PoolUnstake'
import { useClaimV2Pool, useUnstakeV2Pool } from './stakeContract.api'
import { stakePoolChainId } from './stakePoolConst'
import { StakeV2PoolModalContext, StakeV2PoolModalProvider } from './StakeV2PoolModal'

const useStyles = createStyles((theme) => {
  const colors = theme.colors
  return {
    tag: {
      color: '#3C38F5',
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '16px',
      background: '#F1F1FE',
      padding: '2px 6px',
      borderRadius: 16,
    },
    label: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#4F5665',
    },
    value: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20px',
      color: '#3C38F5',
    },
  }
})

function StakeMyLPInfo({ data }: { data: any }) {
  const { account } = useWeb3React()
  const token0 = useCurrency(data.token0, data.chain_id, true)
  const token1 = useCurrency(data.token1, data.chain_id, true)

  const currency0 = token0 && unwrappedToken(token0 as any)
  const currency1 = token1 && unwrappedToken(token1 as any)

  const { formatCurrencyAmount, formatDelta, formatTickPrice } = useFormatter()
  return (
    <>
      <Group
        spacing={8}
        pos="relative"
        position="apart"
        sx={{
          background: '#BABDC41A',
          borderRadius: 6,
          padding: '10px 8px',
        }}
      >
        <Group spacing={4}>
          <DoubleCurrencyLogo currencies={[currency0, currency1]} size={34} />
          <Stack spacing={0}>
            <Group spacing={10}>
              <Text fw={500} fz={16} lh="20px" color="#0B132A">
                {currency0?.symbol}/{currency1?.symbol}
              </Text>
              <Text fw={500} fz={12} lh="16px" color="#3C38F5">
                {formatDelta(parseFloat(new Percent(data?.fee, 1_000_000).toSignificant()))}
              </Text>
            </Group>
            <Group spacing={4}>
              <Text fw={400} fz={12} lh="16px" color="#959AA4">
                {shortenAddress(data.token_address)}
              </Text>

              <CopyButton value={data.token_address || ''} timeout={1000}>
                {({ copy, copied }) => (
                  <Tooltip label="Copied" withinPortal opened={copied}>
                    <ActionIcon
                      size={24}
                      onClick={() => {
                        copy()
                      }}
                    >
                      <PiCopyLight size={12} color="#141414" />
                    </ActionIcon>
                  </Tooltip>
                )}
              </CopyButton>
            </Group>
          </Stack>
        </Group>
      </Group>
    </>
  )
}

function V2StakeMyPoolItem({ showUnwrapped = false, data }: { showUnwrapped?: boolean; data: any }) {
  const smScreen = useLargeThan('sm')
  const mdScreen = useLargeThan('md')
  const location = useLocation()

  const myLPContext = useContext(MyLPContext)
  const [opened, { toggle }] = useDisclosure(false)

  const { classes, cx } = useStyles()

  const currencyA = useCurrency(data.token0, data.chain_id, true)
  const currencyB = useCurrency(data.token1, data.chain_id, true)

  const tokenA =
    currencyA && new Token(data.chain_id, data.token0, currencyA?.decimals, currencyA?.symbol, currencyA?.name)
  const tokenB =
    currencyB && new Token(data.chain_id, data.token1, currencyB?.decimals, currencyB?.symbol, currencyB?.name)

  const pair = useV2Pair(tokenA, tokenB)?.[1]
  //   useMemo(() => {
  //   if (!currencyA || !currencyB || !data) return undefined
  //   const tokenA = new Token(data.chain_id, data.token0, currencyA.decimals, currencyA.symbol, currencyA.name)
  //   const tokenB = new Token(data.chain_id, data.token1, currencyB.decimals, currencyB.symbol, currencyB.name)
  //
  //   const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]
  //   const pair = new Pair(
  //     CurrencyAmount.fromRawAmount(token0, parseUnits(String(data.token0_amount), token0.decimals).toString()),
  //     CurrencyAmount.fromRawAmount(token1, parseUnits(String(data.token1_amount), token1.decimals).toString())
  //   )
  //   return pair
  //   // return undefined
  // }, [data, currencyA, currencyB])

  const [openedRemoveLiquidity, { open: openRemoveLiquidity, close: closeRemoveLiquidity }] = useDisclosure(false)

  const [showClaim, setShowClaim] = useState(false)
  const [showClaimData, setShowClaimData] = useState<LPStakeRewardModel | null>(null)
  const [attemptingTxn, setAttemptingTxn] = useState(false) // clicked confirm

  const { account, chainId, provider } = useWeb3React()

  const currency0 = showUnwrapped ? pair?.token0 : pair?.token0 && unwrappedToken(pair?.token0)
  const currency1 = showUnwrapped ? pair?.token1 : pair?.token1 && unwrappedToken(pair?.token1)

  const [showMore, setShowMore] = useState(false)

  const userPoolBalance = useTokenBalance(account ?? undefined, pair?.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair?.liquidityToken)

  // txn values
  const [txHash, setTxHash] = useState<string>('')

  const handleDismiss = useCallback(() => {
    setShowClaim(false)
    // if there was a tx hash, we want to clear the input
    setAttemptingTxn(false)
    setTxHash('')
  }, [])

  const stakeV2PoolModalContext = useContext(StakeV2PoolModalContext)

  const {
    data: dataLpReward = [],
    loading: dataLpRewardLoading,
    refresh: dataLpRewardRefresh,
  } = useRequest(
    async () => {
      if (!opened) return []
      try {
        const res = await getMyV2LpReward({
          chain: getApiChainParam(stakePoolChainId),
          stake_address: data?.stake_contract,
          owner_address: account || '',
        })

        return res || []
      } catch (e: any) {
        console.log(e)
        return []
      }
    },
    {
      refreshDeps: [opened],
    }
  )

  const claimReward = useClaimV2Pool()
  const addTransaction = useTransactionAdder()
  const claim = useCallback(
    async (stakeContractAddress: string, transactionData: any[] = []) => {
      if (!provider || !stakeContractAddress) {
        return
      }
      try {
        setAttemptingTxn(true)
        const response = await claimReward(stakeContractAddress)
        const transactionInfo: TransactionInfo = {
          type: TransactionType.V2_CLAIM_REWARD,
          data: {
            reward: transactionData,
          },
        }
        addTransaction(response, transactionInfo)
      } catch (e: any) {
        handleDismiss()
        handleErrorMessage(e)
      }
    },
    [addTransaction, claimReward, handleDismiss, provider]
  )

  const { runAsync: claimAll, loading: claimAllLoading } = useRequest(
    async (stakeContractAddress, transactionData: any[], skipRefresh = false) => {
      if (!dataLpReward || !dataLpReward.length) return

      try {
        setShowClaim(true)
        await claim(stakeContractAddress, transactionData)
        if (!skipRefresh) {
          await sleep(10000)
          myLPContext.refresh()
        }
        dataLpRewardRefresh()
      } catch (e: any) {
        console.log(e)
      } finally {
        setShowClaim(false)
        setShowClaimData(null)
      }
    },
    {
      manual: true,
    }
  )
  const canStake = useMemo(() => {
    return !!data?.rewards && data.rewards.length > 0
  }, [data.rewards])
  const unStake = useUnstakeV2Pool()

  const { runAsync: unStakeApi, loading: unStakeApiLoading } = useRequest(
    async (stakeContractAddress, amount, unstakeTransactionData, skipRefresh = false) => {
      try {
        if (canClaim) {
          await claimAll(stakeContractAddress, dataLpReward, skipRefresh)
        }

        const response = await unStake(stakeContractAddress, amount)
        const transactionInfo: TransactionInfo = {
          type: TransactionType.V2_UNSTAKE_LP,
          data: unstakeTransactionData,
        }
        addTransaction(response, transactionInfo)
        if (!skipRefresh) {
          await sleep(14000)
          await myLPContext.refresh()
        }
      } catch (e: any) {
        if (!didUserReject(e)) {
          handleErrorMessage(e)
          console.log(e)
        }
      }
    },
    {
      manual: true,
    }
  )

  const endedCampaignUnstakeData = useMemo(() => {
    const endedCampaign = data.ended_campaign || []
    return endedCampaign.filter((item: any) => Number(item.staked_amount) > 0)
  }, [data])

  const endedCampaignUnstakeAmount = useMemo(() => {
    return endedCampaignUnstakeData.reduce((pre: any, item: any) => {
      return new BigNumberJS(item.staked_amount).plus(pre).toString()
    }, '0')
  }, [endedCampaignUnstakeData])

  const endedCampaignRewardData = useMemo(() => {
    const endedCampaign = data.ended_campaign || []
    return endedCampaign.filter((item: any) => {
      let hasReward = false
      ;(item?.unclaim_reward_list || []).forEach((reward: any) => {
        if (Number(reward.amount) > 0) {
          hasReward = true
        }
      })
      return hasReward
    })
  }, [data])

  const { runAsync: claimEnded, loading: loadingClaimEnded } = useRequest(
    async (skipRefresh = false) => {
      try {
        for (let i = 0; i < endedCampaignRewardData.length; i++) {
          const item = endedCampaignRewardData[i]
          await claimAll(item.stake_contract, item.unclaim_reward_list, true)
        }
        if (!skipRefresh) {
          await sleep(14000)
          myLPContext.refresh()
        }
      } catch (e: any) {
        console.log(e)
      }
    },
    {
      manual: true,
    }
  )
  const { runAsync: unstakeEnded, loading: loadingUnstakeEnded } = useRequest(
    async () => {
      try {
        await claimEnded(true)
        for (let i = 0; i < endedCampaignUnstakeData.length; i++) {
          const item = endedCampaignUnstakeData[i]
          await unStakeApi(
            item.stake_contract,
            item.staked_amount,
            {
              token0: data?.token0,
              token1: data?.token1,
              amount: item.staked_amount,
            },
            true
          )
        }
        await sleep(10000)
        myLPContext.refresh()
      } catch (e: any) {
        console.log(e)
      }
    },
    {
      manual: true,
    }
  )

  const { formatCurrencyAmount, formatDelta, formatTickPrice } = useFormatter()

  const dataLpRewardDesc = useMemo(() => {
    return (dataLpReward || [])
      .filter(Boolean)
      .map((item: any) => {
        return `${mantissaNumber(item?.amount || 0)} ${item?.token_symbol}`
      })
      .join(' | ')
  }, [dataLpReward])
  const canClaim = useMemo(() => {
    const num = Object.values(dataLpReward || []).reduce((pre, cur) => {
      return pre + Number(cur.amount || 0)
    }, 0)
    return num > 0
  }, [dataLpReward])

  const navigate = useNavigate()
  const checkTargetChain = useCheckTargetChain()

  const [openedUnstake, { open: openUnstake, close: closeUnstake }] = useDisclosure(false)

  return (
    <Box
      p={20}
      sx={({ colors }) => ({
        borderRadius: 20,
        background: '#FFFFFF',
        boxShadow: ' 0px 20px 40px -10px #0000000D',
      })}
    >
      <Stack spacing={24}>
        <StakeMyLPInfo data={data} />
        <Stack spacing={12}>
          <Center>
            <Text fw={400} fz={14} lh="16px" color="#4F5665">
              My position
            </Text>
          </Center>
          <Stack spacing={8}>
            <Text fw={700} fz={28} lh="32px" color="#3C38F5" ta="center">
              ~${mantissaNumber(data?.position, 4)}
            </Text>
            <Text fw={500} fz={16} lh="20px" color="#4F5665" ta="center">
              {mantissaNumber(data.token0_amount, 8)} {data.token0_symbol?.replace('WETH', 'ETH')} |{' '}
              {mantissaNumber(data.token1_amount, 8)} {data.token1_symbol?.replace('WETH', 'ETH')}
            </Text>
            {/*<Text fw={500} fz={16} lh="20px" color="#4F5665" ta="center">*/}
            {/*  {data?.token0_amount || 0} {data?.token0_symbol} | {data?.token1_amount || 0} {data?.token1_symbol}*/}
            {/*</Text>*/}
          </Stack>
        </Stack>

        <Group spacing={12}>
          <Tooltip
            width={300}
            withArrow
            label={
              <Stack spacing={8}>
                <Text
                  fw={400}
                  fz={12}
                  lh="16px"
                  color="#FFFFFF"
                  ta="center"
                  sx={({ colors }) => ({
                    wordBreak: 'break-all',
                    whiteSpace: 'break-spaces',
                  })}
                >
                  APR refers to the annualized income from trading fees relative to total value locked (TVL) within a
                  pool and LP rewards.
                </Text>
              </Stack>
            }
            styles={({ colors }) => ({
              tooltip: {
                padding: 10,
                background: '#3C38F5',
                borderRadius: 8,
              },
            })}
          >
            <Stack spacing={5} align="center" sx={{ flex: 1 }}>
              <Text fw={500} fz={18} lh="20px" color="#45AF5C">
                {mantissaNumber((data?.apr || 0) * 100, 2)}%
              </Text>
              <Text fw={400} fz={14} lh="16px" color="#4F5665">
                APR
              </Text>
            </Stack>
          </Tooltip>
        </Group>

        <Grid gutter={8} justify="center">
          {Number(data.available_amount) > 0 && (
            <Grid.Col span="auto">
              <Button
                variant="outline"
                size="lg"
                fullWidth
                disabled={!canStake}
                onClick={(e) => {
                  e.stopPropagation()
                  if (!pair) return
                  stakeV2PoolModalContext.setPair(pair)
                  stakeV2PoolModalContext.setContractAddress(data.stake_contract)
                  stakeV2PoolModalContext.open()
                }}
              >
                Stake
              </Button>
            </Grid.Col>
          )}

          {Number(data.staked_amount) > 0 && (
            <Grid.Col span="auto">
              <Button
                variant="outline"
                size="lg"
                fullWidth
                loading={unStakeApiLoading && !loadingUnstakeEnded}
                onClick={(e) => {
                  e.stopPropagation()
                  openUnstake()
                }}
              >
                Unstake
              </Button>
            </Grid.Col>
          )}

          {!!currency0 && !!currency1 && (
            <Grid.Col span="auto">
              <Link to={`/add/v2/${currencyId(currency0)}/${currencyId(currency1)}`} target="_blank">
                <Button
                  size="lg"
                  fullWidth
                  onClick={async (e) => {
                    const checked = await checkTargetChain(stakePoolChainId)
                    if (!checked) {
                      e.stopPropagation()
                    }
                  }}
                >
                  Add
                </Button>
              </Link>
            </Grid.Col>
          )}

          <Grid.Col span={12}>
            <Button
              variant="default"
              size="lg"
              fullWidth
              bg="#F1F1FE"
              disabled={Number(data.available_amount) <= 0}
              onClick={openRemoveLiquidity}
              sx={{ color: '#3C38F5', border: 0 }}
            >
              Withdraw
            </Button>
          </Grid.Col>
        </Grid>

        <Box p={10} sx={{ background: '#BABDC41A', borderRadius: 6 }}>
          <Group position="apart">
            <Text fw={500} fz={16} lh="20px" color="#4F5665">
              Unclaimed Rewards
            </Text>
            <ActionIcon size={16} onClick={toggle}>
              {!opened ? <GoTriangleDown size={16} color="#4F5665" /> : <GoTriangleUp size={16} color="#4F5665" />}
            </ActionIcon>
          </Group>

          <Collapse in={opened} transitionTimingFunction="linear">
            <Stack spacing={12} mt={12}>
              <Group position="apart">
                <div className={classes.label}>Reward：</div>
                {dataLpRewardLoading ? (
                  <Loader variant="dots" />
                ) : (
                  <div className={classes.value}>{dataLpRewardDesc || '-'}</div>
                )}
              </Group>
              <Button
                size="xs"
                fullWidth
                loading={claimAllLoading}
                disabled={!canClaim}
                onClick={() => {
                  claimAll(data.stake_contract, dataLpReward)
                }}
              >
                Claim
              </Button>
            </Stack>
          </Collapse>
        </Box>

        {(endedCampaignUnstakeData.length > 0 || endedCampaignRewardData.length > 0) && (
          <Box>
            <Stack spacing={12}>
              <Divider color="#F8F8F9" />
              <Text fw={500} fz={12} lh="16px" color="#4F5665">
                Ended campaign
              </Text>
              <Text fw={400} fz={14} lh="16px" color="#4F5665" ta="center">
                LP Token：{mantissaNumber(endedCampaignUnstakeAmount, 18)} {currency0?.symbol}/{currency1?.symbol}
              </Text>

              <Grid gutter={8} justify="center">
                {endedCampaignUnstakeData.length > 0 && (
                  <Grid.Col span="content">
                    <Button
                      m="auto"
                      w={230}
                      size="lg"
                      variant="outline"
                      sx={({ colors }) => ({
                        borderColor: '#FE9839',
                        color: '#FE9839',
                        padding: 0,
                        fontSize: 16,
                        fontWeight: 600,
                        lineHeight: '20px',
                      })}
                      loading={loadingUnstakeEnded}
                      onClick={unstakeEnded}
                    >
                      unstake ended lp token
                    </Button>
                  </Grid.Col>
                )}
                {endedCampaignRewardData.length > 0 && (
                  <Grid.Col span="auto">
                    <Button
                      fullWidth
                      size="lg"
                      sx={({ colors }) => ({
                        backgroundColor: '#FE9839',
                        color: '#FFFFFF',
                        padding: 0,
                        fontSize: 16,
                        fontWeight: 600,
                        lineHeight: '20px',
                      })}
                      loading={loadingClaimEnded}
                      onClick={claimEnded}
                    >
                      Claim Rewards
                    </Button>
                  </Grid.Col>
                )}
              </Grid>
            </Stack>
          </Box>
        )}
      </Stack>
      <V2RemoveLiquidityModal
        opened={openedRemoveLiquidity}
        onClose={closeRemoveLiquidity}
        currencyIdA={currency0 && currencyId(currency0)}
        currencyIdB={currency1 && currencyId(currency1)}
      />
      <TransactionConfirmationModal
        isOpen={showClaim}
        onDismiss={handleDismiss}
        attemptingTxn={attemptingTxn}
        hash={txHash ? txHash : ''}
        reviewContent={() => <></>}
        title="Claim rewards"
        pendingText="Collect rewards"
      />
      <ModalV2PoolUnstake
        data={data}
        pair={pair}
        amount={data?.staked_amount || '0'}
        opened={openedUnstake}
        onClose={closeUnstake}
        confirmUnstake={(stakeContractAddress: string, value: string, skipRefresh?: boolean) => {
          unStakeApi(
            stakeContractAddress,
            value,
            {
              token0: data?.token0,
              token1: data?.token1,
              amount: value,
            },
            skipRefresh
          )
        }}
      />
    </Box>
  )
}

export default function V2StakeMyPoolList({ list = [] }: { list: any[] }) {
  const smScreen = useLargeThan('sm')
  const mdScreen = useLargeThan('md')
  const location = useLocation()
  const { account } = useWeb3React()

  return (
    <>
      <StakeV2PoolModalProvider>
        <Grid gutter={24}>
          {list?.length > 0 && (
            <>
              {list.map((item, i) => (
                <Grid.Col span={4} key={i}>
                  <V2StakeMyPoolItem data={item} />
                </Grid.Col>
              ))}
            </>
          )}
        </Grid>
      </StakeV2PoolModalProvider>
    </>
  )
}
