import {
  AppShell,
  Box,
  Button,
  Center,
  Container,
  Grid,
  Group,
  Pagination,
  Skeleton,
  Stack,
  Tabs,
  Text,
} from '@asuikit/core'
import { ChainId } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { useRequest } from 'ahooks'
import { CHAIN_INFO, getApiChainParam, useSupportedChainId } from 'constants/chains'
import { useCurrency } from 'hooks/Tokens'
import useParsedQueryString from 'hooks/useParsedQueryString'
import { createContext, useEffect, useMemo, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { queryParametersToCurrencyState } from 'state/swap/hooks'
import { STATIC_BASEURL } from '../../constants/detrade'
import { useLargeThan } from '../../hooks/detrade/useWidthQuery'
import useCheckTargetChain from '../../hooks/useCheckTargetChain'
import { useV3Positions } from '../../hooks/useV3Positions'
import { getMyPoolsList, getMyV2PoolsList, getPoolsHeader } from '../../service/pools.api'
import { mantissaNumber } from '../../utils/detrade/number'
import StakeMyPoolList from './StakeMyPoolList'
import StakePoolList from './StakePoolList'
import { stakePoolChainId } from './stakePoolConst'

import { useDisclosure } from '@asuikit/hooks'
import { useToggleAccountDrawer } from '../../components/AccountDrawer/MiniPortfolio/hooks'
import NoData from '../../components/detrade/NoData'
import { useConnectionReady } from '../../connection/eagerlyConnect'
import { useSwitchChain } from '../../hooks/useSwitchChain'
import { Trans } from '../../i18n'
import { LPInfoItem } from '../../service/pools.api.d'
import { didUserReject } from '../../utils/swapErrorToUserReadableMessage'
import ModalSubmitReward from './ModalSubmitReward'
import PoolTypeSelector from './PoolTypeSelector'
import V2StakeMyPoolList from './V2StakeMyPoolList'
// @ts-ignore
export const getTabsStyle: any = ({ colors, fn }) => {
  return {
    tabsList: {
      borderBottomColor: colors.line[2],
      borderBottomWidth: 1,
      flexWrap: 'nowrap',
      width: '100%',
      overflowX: 'auto',
      overflowY: 'hidden',
      marginBottom: 0,
    },
    tab: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 14,
      paddingBottom: 14,
      marginRight: 24,
      marginBottom: 0,
      color: '#959AA4',
      [fn.smallerThan('md')]: {
        paddingTop: 0,
        paddingBottom: 0,
        marginRight: 32,
      },
      '&:hover': {
        borderBottomWidth: 2,
        borderStyle: 'solid',
      },
      '&[data-active],&[data-active]:hover': {
        color: '#0B132A',
        borderBottomColor: colors.text[1],
        borderBottomWidth: 2,
        borderStyle: 'solid',
        '.asuikit-Tabs-tabLabel': {
          fontWeight: 600,
        },
      },
    },
    tabLabel: {
      fontWeight: 400,
      fontSize: 18,
      lineHeight: '24px',
      [fn.smallerThan('md')]: {
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '20px',
      },
    },
    panel: {},
  }
}

type MyContextType = {
  LPList: LPInfoItem[]
  refresh: () => void
}

export const MyLPContext = createContext<MyContextType>({
  LPList: [],
  refresh: () => undefined,
})
export default function StakePoolPage({ className }: { className?: string }) {
  const smScreen = useLargeThan('sm')
  const mdScreen = useLargeThan('md')
  const location = useLocation()

  const connectionReady = useConnectionReady()
  const { account, chainId: connectedChainId, connector } = useWeb3React()
  const supportedChainId = useSupportedChainId(connectedChainId)
  const chainId = supportedChainId || ChainId.ALIENXCHAIN

  const parsedQs = useParsedQueryString()
  const parsedCurrencyState = useMemo(() => {
    return queryParametersToCurrencyState(parsedQs)
  }, [parsedQs])

  const initialInputCurrency = useCurrency(parsedCurrencyState.inputCurrencyId, chainId)
  const initialOutputCurrency = useCurrency(parsedCurrencyState.outputCurrencyId, chainId)

  const [activeTab, setActiveTab] = useState<string | null>('pools')

  const [_, setQuery] = useSearchParams()
  const query = useParsedQueryString()
  const { tab, poolType } = query
  useEffect(() => {
    if (tab === 'my-pools') {
      setActiveTab(tab)
      return
    }
    setActiveTab('pools')
  }, [tab])

  const { data: info } = useRequest(async () => {
    const res = await getPoolsHeader({
      chain: getApiChainParam(stakePoolChainId),
    })
    return res
  }, {})
  const data = useMemo(() => {
    return [
      {
        label: 'TVL:',
        value: `~$${mantissaNumber(info?.tvl, 2)}`,
      },
      {
        label: 'Fees:',
        value: `~$${mantissaNumber(info?.fees, 2)}`,
      },

      {
        label: 'Volume:',
        value: `~$${mantissaNumber(info?.volume, 2)}`,
      },
    ]
  }, [info?.fees, info?.tvl, info?.volume])

  const { positions, loading: positionsLoading } = useV3Positions(account)
  const checkTargetChain = useCheckTargetChain()

  const {
    data: LPList = {
      list: [],
      total: 0,
      page: 1,
    },
    loading,
    refresh,
    run,
    mutate,
  } = useRequest(
    async (page = 1) => {
      if (!account || !connectedChainId) return
      if (poolType === 'v3') {
        const res = await getMyPoolsList({
          address: account,
          chain: getApiChainParam(chainId),
          page,
          pageSize: 6,
        })
        return {
          list: res.results || [],
          total: res.total,
          page,
        }
      } else {
        const res = await getMyV2PoolsList({
          address: account,
          chain: getApiChainParam(chainId),
          page,
          pageSize: 6,
        })
        return {
          list: res.results || [],
          total: res.total,
          page,
        }
      }
    },
    {
      refreshDeps: [account, connectedChainId, poolType],
      pollingInterval: 10000,
    }
  )

  function onPageChange(page: any) {
    mutate({
      ...LPList,
      list: [],
    })
    run(page)
  }

  const switchChain = useSwitchChain()

  // toggle wallet when disconnected
  const toggleWalletDrawer = useToggleAccountDrawer()

  const [opened, { open, close }] = useDisclosure(false)

  return (
    <AppShell
      padding={0}
      // footer={<Footer fixed={false} />}
      styles={(theme) => ({
        main: {
          background: 'linear-gradient(180deg, #FFFFFF 7.67%, #F7F7F7 100%)',
        },
      })}
    >
      <Stack
        py={40}
        sx={({ colors }) => ({
          minHeight: 220,
          width: '100%',
          backgroundImage: `url(${STATIC_BASEURL}/images/detrade/stake-pool/bg.png)`,
          backgroundSize: '100% 100%',
        })}
      >
        <Container
          mt={0}
          px={smScreen ? 60 : 16}
          size={1440}
          sx={{ flex: 1, width: '100%', display: 'flex', flexDirection: 'column' }}
        >
          <Group
            h="100%"
            position="apart"
            align="flex-end"
            sx={({ colors }) => ({
              flex: 1,
            })}
          >
            <Stack h="100%">
              <Box
                maw={736}
                onClick={() => {
                  checkTargetChain(ChainId.ALIENXCHAIN)
                }}
              >
                <Text fw={800} fz={24} lh="32px" color="#0B132A">
                  Liquidity Providers (LPs) enable low-slippage swaps by depositing and staking their liquidity, earning
                  rewards in return.
                </Text>
              </Box>

              <Group spacing={16}>
                {data.map((item, index) => (
                  <Box
                    key={index}
                    sx={({ colors }) => ({
                      backgroundColor: '#FFFFFF',
                      borderRadius: 12,
                      padding: '10px 12px',
                    })}
                  >
                    <Text fw={600} fz={16} lh="24px" color="blue.9" span>
                      {item.label}
                    </Text>
                    <Text fw={600} fz={16} lh="24px" color="blue.9" span>
                      {item.value}
                    </Text>
                  </Box>
                ))}
              </Group>
            </Stack>
            <Box mb={14}>
              <a onClick={open} style={{ cursor: 'pointer' }}>
                <Text fw={500} fz={12} lh="16px" color="#0B132A">
                  Set new reward pool for your token {'>'}
                </Text>
              </a>
            </Box>
          </Group>
        </Container>
      </Stack>
      <Container mt={8} px={smScreen ? 60 : 16} size={1440}>
        <Tabs
          value={activeTab}
          onTabChange={(val) => {
            setQuery({
              ...query,
              tab: val,
            } as any)
          }}
          keepMounted={false}
          styles={({ colors, fn }) => ({
            ...getTabsStyle({ colors, fn }),
            root: {
              position: 'relative',
            },
          })}
        >
          <Tabs.List>
            <Tabs.Tab value="pools">Pools</Tabs.Tab>
            <Tabs.Tab value="my-pools">
              My pools
              {Number(LPList?.total || 0) > 0 && <>({LPList?.total || 0})</>}
            </Tabs.Tab>
          </Tabs.List>
          <Box
            sx={({ colors }) => ({
              position: 'absolute',
              right: 0,
              top: 10,
            })}
          >
            <PoolTypeSelector
              onChange={() => {
                mutate({
                  ...LPList,
                  list: [],
                })
              }}
            />
          </Box>

          <Tabs.Panel value="pools">
            <StakePoolList />
          </Tabs.Panel>
          <Tabs.Panel value="my-pools">
            <Box py={37}>
              {loading && !LPList?.list?.length && (
                <>
                  <Grid gutter={24}>
                    {new Array(10).fill(undefined).map((item, index) => (
                      <Grid.Col span={4} key={index}>
                        <Box
                          w={424}
                          p={20}
                          sx={({ colors }) => ({
                            borderRadius: 20,
                            background: '#FFFFFF',
                            boxShadow: ' 0px 20px 40px -10px #0000000D',
                          })}
                        >
                          <Stack spacing={24}>
                            <Group noWrap>
                              <Skeleton height={50} circle />
                              <Stack sx={{ flexGrow: 1 }}>
                                <Skeleton height={10} width="50%" radius="xl" />

                                <Skeleton height={10} radius="xl" />
                              </Stack>
                            </Group>
                            <Stack spacing={12}>
                              <Center>
                                <Skeleton height={20} width="30%" />
                              </Center>
                              <Center>
                                <Skeleton height={20} width="50%" />
                              </Center>
                              <Center>
                                <Skeleton height={20} width="30%" />
                              </Center>
                              <Group position="apart">
                                <Skeleton height={30} width="40%" />
                                <Skeleton height={30} width="40%" />
                              </Group>
                            </Stack>

                            <Group position="apart" mt={20}>
                              <Skeleton height={40} width="45%" radius="xl" />
                              <Skeleton height={40} width="45%" radius="xl" />
                            </Group>
                          </Stack>
                        </Box>
                      </Grid.Col>
                    ))}
                  </Grid>
                </>
              )}
              {!!account && stakePoolChainId === connectedChainId ? (
                <MyLPContext.Provider value={{ LPList: LPList?.list || [], refresh }}>
                  {LPList?.list?.length > 0 ? (
                    <>
                      {poolType === 'v3' ? (
                        <StakeMyPoolList list={LPList?.list || []} />
                      ) : (
                        <V2StakeMyPoolList list={LPList?.list || []} />
                      )}

                      <Center py={32}>
                        <Pagination
                          radius="lg"
                          boundaries={2}
                          siblings={2}
                          value={LPList?.page}
                          onChange={onPageChange}
                          total={Math.ceil(LPList.total / 6)}
                        />
                      </Center>
                    </>
                  ) : (
                    <Box mt={100}>
                      <NoData />
                    </Box>
                  )}
                </MyLPContext.Provider>
              ) : (
                <>
                  <Center mt={100}>
                    {connectionReady && !account ? (
                      <>
                        <Button size="xl" onClick={toggleWalletDrawer}>
                          <Trans>Connect wallet</Trans>
                        </Button>
                      </>
                    ) : (
                      <>
                        {stakePoolChainId && stakePoolChainId !== connectedChainId && (
                          <Button
                            size="xl"
                            onClick={async () => {
                              try {
                                await switchChain(connector, stakePoolChainId)
                              } catch (error) {
                                if (didUserReject(error)) {
                                  // Ignore error, which keeps the user on the previous chain.
                                } else {
                                  // TODO(WEB-3306): This UX could be improved to show an error state.
                                  throw error
                                }
                              }
                            }}
                          >
                            Connect to {CHAIN_INFO[stakePoolChainId].label}
                          </Button>
                        )}
                      </>
                    )}
                  </Center>
                </>
              )}
            </Box>
          </Tabs.Panel>
        </Tabs>
        <ModalSubmitReward opened={opened} onClose={close} />
      </Container>
    </AppShell>
  )
}
