import { ActionIcon, Box, Button, CopyButton, createStyles, Divider, Group, Stack, Text, Tooltip } from '@asuikit/core'
import { ChainId, Percent } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { getApiChainParam, useSupportedChainId } from 'constants/chains'
import { useCurrency, useToken } from 'hooks/Tokens'
import useParsedQueryString from 'hooks/useParsedQueryString'
import { identity, pickBy } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { HiMiniExclamationCircle } from 'react-icons/hi2'
import { PiCopyLight } from 'react-icons/pi'
import { useLocation, useNavigate } from 'react-router-dom'
import { queryParametersToCurrencyState } from 'state/swap/hooks'
import { shortenAddress } from 'utilities/src/addresses'
import ASDataTable from '../../components/detrade/ASDataTable'
import { DoubleCurrencyLogo } from '../../components/DoubleLogo'
import { useLargeThan } from '../../hooks/detrade/useWidthQuery'
import useCheckTargetChain from '../../hooks/useCheckTargetChain'
import { getPoolsList } from '../../service/pools.api'
import { currencyId } from '../../utils/currencyId'
import { mantissaNumber } from '../../utils/detrade/number'
import { useFormatter } from '../../utils/formatNumbers'
import { unwrappedToken } from '../../utils/unwrappedToken'
import { stakePoolChainId } from './stakePoolConst'

const useStyles = createStyles((theme) => {
  const colors = theme.colors
  return {
    tag: {
      color: '#3C38F5',
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '16px',
      background: '#F1F1FE',
      padding: '2px 6px',
      borderRadius: 16,
    },
  }
})

function CurrencyItem({ positionDetail }: any) {
  const { token0: token0Address, token1: token1Address, fee: feeAmount, poolAddress } = positionDetail

  const token0 = useToken(token0Address, stakePoolChainId)
  const token1 = useToken(token1Address, stakePoolChainId)
  const currency0 = token0 ? unwrappedToken(token0) : undefined
  const currency1 = token1 ? unwrappedToken(token1) : undefined
  const currencyQuote = currency1 && unwrappedToken(currency1)
  const currencyBase = currency0 && unwrappedToken(currency0)
  const { formatCurrencyAmount, formatDelta, formatTickPrice } = useFormatter()
  const { classes, cx } = useStyles()
  if (Object.keys(positionDetail).length <= 0) {
    return null
  }
  return (
    <Group spacing={4} noWrap>
      <DoubleCurrencyLogo currencies={[currencyBase, currencyQuote]} size={32} />

      <Stack spacing={4} sx={{ flexGrow: 1 }}>
        <Group spacing={8} noWrap>
          <Text fw={500} fz={16} lh="20px" color="#0B132A">
            {currencyBase?.symbol}&nbsp;/&nbsp;{currencyQuote?.symbol}
          </Text>

          <Box className={classes.tag}>
            {formatDelta(parseFloat(new Percent(feeAmount, 1_000_000).toSignificant()))}
          </Box>
        </Group>

        <Group spacing={4}>
          <Text fw={400} fz={12} lh="16px" color="#959AA4">
            {shortenAddress(poolAddress)}
          </Text>

          <CopyButton value={poolAddress || ''} timeout={1000}>
            {({ copy, copied }) => (
              <Tooltip label="Copied" withinPortal opened={copied}>
                <ActionIcon
                  size={24}
                  onClick={() => {
                    copy()
                  }}
                >
                  <PiCopyLight size={12} color="#141414" />
                </ActionIcon>
              </Tooltip>
            )}
          </CopyButton>
        </Group>
      </Stack>
    </Group>
  )
}

function DepositButton({ item }: any) {
  const token0 = useToken(item.token0)
  const token1 = useToken(item.token1)

  const currency0 = token0 ? unwrappedToken(token0) : undefined
  const currency1 = token1 ? unwrappedToken(token1) : undefined
  const navigate = useNavigate()
  const checkTargetChain = useCheckTargetChain()
  const query = useParsedQueryString()
  const { poolType } = query
  return (
    <Button
      size="sm"
      variant="default"
      bg="#F1F1FE"
      sx={({ colors }) => ({
        border: 0,
        color: colors.blue[9],
      })}
      onClick={async () => {
        await checkTargetChain(stakePoolChainId)
        let link = `/add/${currencyId(currency0)}/${currencyId(currency1)}`
        if (poolType !== 'v3') {
          link = `/add/v2/${currencyId(currency0)}/${currencyId(currency1)}`
        }
        navigate(link)
      }}
    >
      Deposit
    </Button>
  )
}

export default function StakePoolList() {
  const smScreen = useLargeThan('sm')
  const mdScreen = useLargeThan('md')
  const location = useLocation()

  const { chainId: connectedChainId } = useWeb3React()
  const supportedChainId = useSupportedChainId(connectedChainId)
  const chainId = supportedChainId || ChainId.ALIENXCHAIN

  const parsedQs = useParsedQueryString()
  const parsedCurrencyState = useMemo(() => {
    return queryParametersToCurrencyState(parsedQs)
  }, [parsedQs])

  const initialInputCurrency = useCurrency(parsedCurrencyState.inputCurrencyId, chainId)
  const initialOutputCurrency = useCurrency(parsedCurrencyState.outputCurrencyId, chainId)

  const { t, i18n } = useTranslation()

  const navigate = useNavigate()
  const checkTargetChain = useCheckTargetChain()
  const columns = useMemo<any[]>(
    () => [
      {
        title: <>Pools</>,
        align: 'left',
        width: 380,
        render(text: any, item: any, index: any) {
          return (
            <CurrencyItem
              positionDetail={{
                token0: item.token0,
                token1: item.token1,
                fee: item.fee,
                poolAddress: item.pool_address,
              }}
            />
          )
          // return null
        },
      },
      {
        title: <>TVL</>,
        align: 'left',
        sortKey: 'tvl',
        render(text: any, item: any, rowIndex: any): any {
          return (
            <Stack spacing={4}>
              <Text>${mantissaNumber(item.tvl, 2)}</Text>
              <Text fw={400} fz={12} lh="16px" color="#959AA4">
                {mantissaNumber(item.token0_tvl, 2)} {item.token0_symbol.replace('WETH', 'ETH')} |{' '}
                {mantissaNumber(item.token1_tvl, 2)} {item.token1_symbol.replace('WETH', 'ETH')}
              </Text>
            </Stack>
          )
        },
      },
      {
        title: (
          <Group spacing={5}>
            APR
            <Tooltip
              width={300}
              withArrow
              label={
                <Stack spacing={8}>
                  <Text
                    fw={400}
                    fz={12}
                    lh="16px"
                    color="#FFFFFF"
                    ta="center"
                    sx={({ colors }) => ({
                      wordBreak: 'break-all',
                      whiteSpace: 'break-spaces',
                    })}
                  >
                    APR refers to the annaulized income from trading fees relative to total value locked (TVL) within a
                    pool and LP rewards.
                  </Text>
                  <Divider color="#FFFFFF" />
                  <Text fw={400} fz={12} lh="16px">
                    <Text ta="center">Fee APR = 24H Fees / TVL*365</Text>
                    <Text ta="center">Reward APR = 24H reward / TVL *365</Text>
                  </Text>
                </Stack>
              }
              styles={({ colors }) => ({
                tooltip: {
                  padding: 10,
                  background: '#3C38F5',
                  borderRadius: 8,
                },
              })}
            >
              <Group
                sx={{
                  transform: 'rotate(180deg)',
                }}
              >
                <HiMiniExclamationCircle size={14} color="#BABDC4" />
              </Group>
            </Tooltip>
          </Group>
        ),
        align: 'right',
        sortKey: 'apr',
        render(text: any, item: any, rowIndex: any): any {
          return (
            <Tooltip
              width="auto"
              withArrow
              position="right"
              label={
                <Stack spacing={8}>
                  <Text fw={400} fz={12} lh="16px">
                    <Text ta="center">Fee APR：{mantissaNumber((item.fee_apr || 0) * 100, 2)}%</Text>
                    <Text ta="center">Reward APR：{mantissaNumber((item.reward_apr || 0) * 100, 2)}%</Text>
                  </Text>
                </Stack>
              }
              styles={({ colors }) => ({
                tooltip: {
                  padding: 10,
                  background: '#3C38F5',
                  borderRadius: 8,
                },
              })}
            >
              <Text color="#45AF5C">{mantissaNumber((item.apr || 0) * 100, 2)}%</Text>
            </Tooltip>
          )
        },
      },

      {
        title: (
          <Group spacing={5}>
            Volume
            <Tooltip
              width={300}
              withArrow
              label={
                <Stack spacing={8}>
                  <Text
                    fw={400}
                    fz={12}
                    lh="16px"
                    color="#FFFFFF"
                    ta="center"
                    sx={({ colors }) => ({
                      wordBreak: 'break-all',
                      whiteSpace: 'break-spaces',
                    })}
                  >
                    Volume is the amount of the asset that has been traded on Detrade during last 24 Hours.
                  </Text>
                </Stack>
              }
              styles={({ colors }) => ({
                tooltip: {
                  padding: 10,
                  background: '#3C38F5',
                  borderRadius: 8,
                },
              })}
            >
              <Group
                sx={{
                  transform: 'rotate(180deg)',
                }}
              >
                <HiMiniExclamationCircle size={14} color="#BABDC4" />
              </Group>
            </Tooltip>
          </Group>
        ),
        align: 'right',
        render(text: any, item: any, rowIndex: any): any {
          return `$${mantissaNumber(item.volume, 2)}`
        },
      },
      {
        title: (
          <>
            Rewards{' '}
            <Text span color="#3C38F5">
              (Daily)
            </Text>
          </>
        ),
        align: 'right',
        render(text: any, item: any, rowIndex: any): any {
          const rewards = item?.rewards || []
          return (
            <Stack spacing={4}>
              {rewards.map((data: any, i: number) => (
                <Text color="#3C38F5" key={i}>
                  + {mantissaNumber(data.amount)} {data.token_symbol}
                </Text>
              ))}
            </Stack>
          )
        },
      },
      {
        title: <></>,
        align: 'right',
        render(text: any, item: any, rowIndex: any): any {
          return <DepositButton item={item} />
        },
      },
    ],
    []
  )

  const query = useParsedQueryString()
  const { poolType } = query
  const init = useCallback(
    async (params: any, { page, pageSize }: any, sortParams = {}) => {
      try {
        const paramsCfg: any = {
          chain: getApiChainParam(stakePoolChainId),
          page,
          pageSize,
          sortBy: 'tvl',
          sortDirection: 'desc',
          poolType: poolType || 'v2',
          ...sortParams,
        }

        const res = await getPoolsList(pickBy(paramsCfg, identity) as any)
        const result = {
          list: res.results || [],
          total: res.total,
          page,
        }
        return result
      } catch (e: any) {
        console.log(e)
        return {
          list: [],
          total: 0,
          page: 1,
        }
      }
    },
    [poolType, stakePoolChainId]
  )
  return (
    <ASDataTable
      hasXSpace
      columns={columns}
      request={init}
      verticalSpacing={0}
      pagination={true}
      headerSticky={true}
      styles={({ colors }: any) => {
        return {
          header: {
            // borderRadius: 8,
            // background: colors.bg[2],
            borderBottom: '1px solid #BABDC433',
          },
          tr: {
            borderBottom: '1px solid #BABDC433',
            '&:nth-child(even)': {
              // background: colors.bg[2],
              // borderRadius: 8,
            },
          },
          th: {
            height: 40,
            fontSize: 14,
            fontWeight: 400,
            lineHeight: '20px',
            // padding: 6,
            // padding: '10px 12px 10px',
            padding: '10px 8px',
            color: '#959AA4',
            borderBottom: 0,
          },
          td: {
            height: 80,
            wordBreak: 'break-word',
            padding: '21px 8px',
            color: '#0B132A',
            fontSize: 16,
            fontWeight: 500,
            lineHeight: '20px',
          },
        }
      }}
    />
  )
}
