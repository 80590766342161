import {STATIC_BASEURL} from '../../constants/detrade'
import {
  USDC_ALIENX_HAL,
  USDC_ALIENXCHAIN,
  USDC_SONICTESTNET,
  USDT_ALIENX_HAL,
  USDT_ALIENXCHAIN,
  WETH_ALIENX_HAL,
  WETH_ALIENXCHAIN,
  WETH_SONICTESTNET,
} from '../../constants/tokens'

const usdcList = [USDC_ALIENX_HAL.address, USDC_ALIENXCHAIN.address, USDC_SONICTESTNET.address]
const usdtList = [USDT_ALIENX_HAL.address, USDT_ALIENXCHAIN.address]
const wethList = [WETH_ALIENX_HAL.address, WETH_ALIENXCHAIN.address]
const list: string[] = []

export const detradeTokenLogoMap = [...list, ...wethList, ...usdcList, ...usdtList].reduce<{ [_: string]: string }>(
  (pre, address) => {
    if (wethList.includes(address)) {
      pre[address] = `${STATIC_BASEURL}/images/detrade/token-logo/weth.png`
    } else if (usdcList.includes(address)) {
      pre[address] = `${STATIC_BASEURL}/images/detrade/token-logo/usdc.png`
    } else if (usdtList.includes(address)) {
      pre[address] = `${STATIC_BASEURL}/images/detrade/token-logo/usdt.png`
    } else {
      pre[address] = `${STATIC_BASEURL}/images/detrade/token-logo/${address}.png`
    }
    return pre
  },
  {}
)
