import { Currency, Token } from '@uniswap/sdk-core'
import { SupportedInterfaceChainId, chainIdToBackendChain, getApiChainParam } from 'constants/chains'
import { NATIVE_CHAIN_ID, isDetradeNetwork, nativeOnChain } from 'constants/tokens'
import { apolloClient } from 'graphql/data/apollo/client'
import { gqlTokenToCurrencyInfo } from 'graphql/data/types'
import {
  Token as InfoToken,
  SimpleTokenDocument,
  SimpleTokenQuery,
} from 'uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks'

import { buildCurrencyInfo } from 'constants/routing'
import { find } from 'lodash'
import { getTokenSearch } from '../../../../service/pools.api'

export async function getCurrency(
  currencyId: string,
  chainId: SupportedInterfaceChainId
): Promise<Currency | undefined> {
  const isNative =
    currencyId === NATIVE_CHAIN_ID || currencyId?.toLowerCase() === 'native' || currencyId?.toLowerCase() === 'eth'
  if (isNative) {
    return nativeOnChain(chainId)
  } else if (isDetradeNetwork(chainId)) {
    const res = await getTokenSearch({
      chain: getApiChainParam(Number(chainId)),
      q: currencyId.toLowerCase(),
    })
    const localTokenList = res.map((token) => ({
      address: token.token_address,
      circulating_market_cap: null,
      decimals: String(token.token_decimals),
      exchange_rate: null,
      holders: null,
      icon_url: null,
      name: token.token_name,
      symbol: token.token_symbol,
      total_supply: null,
      type: 'ERC-20',
      volume_24h: null,
    }))
    const item = find(localTokenList, function (obj) {
      return obj.address.toLowerCase() === currencyId.toLowerCase()
    })
    if (!item) return undefined
    const token = new Token(chainId, item.address, Number(item.decimals), item.symbol || item.name, item.name)
    return buildCurrencyInfo(token)?.currency
  }

  const { data } = await apolloClient.query<SimpleTokenQuery>({
    query: SimpleTokenDocument,
    variables: {
      address: currencyId,
      chain: chainIdToBackendChain({ chainId }),
    },
  })
  return gqlTokenToCurrencyInfo(data?.token as InfoToken)?.currency
}
