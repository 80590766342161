import { TradeType } from '@uniswap/sdk-core'

import { BigNumber } from '@ethersproject/bignumber'
import { ProjectModel } from 'service/launch.api.d'
import {
  AssetActivityPartsFragment,
  TransactionDetailsPartsFragment,
} from 'uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks'
import { VoteOption } from '../governance/types'

export type TransactionActivity = AssetActivityPartsFragment & { details: TransactionDetailsPartsFragment }

export interface SerializableTransactionReceipt {
  to: string
  from: string
  contractAddress: string
  transactionIndex: number
  blockHash: string
  transactionHash: string
  blockNumber: number
  status?: number
}

/**
 * Be careful adding to this enum, always assign a unique value (typescript will not prevent duplicate values).
 * These values is persisted in state and if you change the value it will cause errors
 */
export enum TransactionType {
  APPROVAL = 0,
  SWAP,
  DEPOSIT_LIQUIDITY_STAKING,
  WITHDRAW_LIQUIDITY_STAKING,
  CLAIM,
  VOTE,
  DELEGATE,
  WRAP,
  CREATE_V3_POOL,
  ADD_LIQUIDITY_V3_POOL,
  ADD_LIQUIDITY_V2_POOL,
  MIGRATE_LIQUIDITY_V3,
  COLLECT_FEES,
  REMOVE_LIQUIDITY_V3,
  SUBMIT_PROPOSAL,
  QUEUE,
  EXECUTE,
  BUY,
  SEND,
  RECEIVE,
  MINT,
  BURN,
  BORROW,
  REPAY,
  DEPLOY,
  CANCEL,
  LIMIT,
  CLAIM_REWARD,
  STAKE_LP,
  UNSTAKE_LP,
  V2_CLAIM_REWARD,
  V2_STAKE_LP,
  V2_UNSTAKE_LP,
  LAUNCH_DEPOSIT,
  LAUNCH_CLAIM_REFUND,
  LAUNCH_CLAIM_TOKEN,
  CREATE_TOKEN,
}

interface BaseTransactionInfo {
  type: TransactionType
}

export interface VoteTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.VOTE
  governorAddress: string
  proposalId: number
  decision: VoteOption
  reason: string
}

export interface QueueTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.QUEUE
  governorAddress: string
  proposalId: number
}

export interface ExecuteTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.EXECUTE
  governorAddress: string
  proposalId: number
}

export interface DelegateTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.DELEGATE
  delegatee: string
}

export interface ApproveTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.APPROVAL
  tokenAddress: string
  spender: string
  amount: string
}

interface BaseSwapTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.SWAP
  tradeType: TradeType
  inputCurrencyId: string
  outputCurrencyId: string
  isUniswapXOrder: boolean
}

export interface ExactInputSwapTransactionInfo extends BaseSwapTransactionInfo {
  tradeType: TradeType.EXACT_INPUT
  inputCurrencyAmountRaw: string
  expectedOutputCurrencyAmountRaw: string
  minimumOutputCurrencyAmountRaw: string
  settledOutputCurrencyAmountRaw?: string
}
export interface ExactOutputSwapTransactionInfo extends BaseSwapTransactionInfo {
  tradeType: TradeType.EXACT_OUTPUT
  outputCurrencyAmountRaw: string
  expectedInputCurrencyAmountRaw: string
  maximumInputCurrencyAmountRaw: string
}

interface DepositLiquidityStakingTransactionInfo {
  type: TransactionType.DEPOSIT_LIQUIDITY_STAKING
  token0Address: string
  token1Address: string
}

interface WithdrawLiquidityStakingTransactionInfo {
  type: TransactionType.WITHDRAW_LIQUIDITY_STAKING
  token0Address: string
  token1Address: string
}

export interface WrapTransactionInfo {
  type: TransactionType.WRAP
  unwrapped: boolean
  currencyAmountRaw: string
  chainId?: number
}

export interface ClaimTransactionInfo {
  type: TransactionType.CLAIM
  recipient: string
  uniAmountRaw?: string
}

export interface CreateV3PoolTransactionInfo {
  type: TransactionType.CREATE_V3_POOL
  baseCurrencyId: string
  quoteCurrencyId: string
}

export interface AddLiquidityV3PoolTransactionInfo {
  type: TransactionType.ADD_LIQUIDITY_V3_POOL
  createPool: boolean
  baseCurrencyId: string
  quoteCurrencyId: string
  feeAmount: number
  expectedAmountBaseRaw: string
  expectedAmountQuoteRaw: string
}

export interface AddLiquidityV2PoolTransactionInfo {
  type: TransactionType.ADD_LIQUIDITY_V2_POOL
  baseCurrencyId: string
  quoteCurrencyId: string
  expectedAmountBaseRaw: string
  expectedAmountQuoteRaw: string
}

export interface MigrateV2LiquidityToV3TransactionInfo {
  type: TransactionType.MIGRATE_LIQUIDITY_V3
  baseCurrencyId: string
  quoteCurrencyId: string
  isFork: boolean
}

export interface CollectFeesTransactionInfo {
  type: TransactionType.COLLECT_FEES
  currencyId0: string
  currencyId1: string
  expectedCurrencyOwed0: string
  expectedCurrencyOwed1: string
}

export interface RemoveLiquidityV3TransactionInfo {
  type: TransactionType.REMOVE_LIQUIDITY_V3
  baseCurrencyId: string
  quoteCurrencyId: string
  expectedAmountBaseRaw: string
  expectedAmountQuoteRaw: string
}

interface SubmitProposalTransactionInfo {
  type: TransactionType.SUBMIT_PROPOSAL
}

export interface SendTransactionInfo {
  type: TransactionType.SEND
  currencyId: string
  amount: string
  recipient: string
}

export interface ClaimRewardTransactionInfo {
  type: TransactionType.CLAIM_REWARD
  data: any
}
export interface StakeLpTransactionInfo {
  type: TransactionType.STAKE_LP
  data: {
    token0: string
    token1: string
    tokenId: BigNumber
    fee: number
    liquidity: BigNumber
    tickLower: number
    tickUpper: number
  }
}
export interface UnstakeLpTransactionInfo {
  type: TransactionType.UNSTAKE_LP
  data: {
    token0: string
    token1: string
    tokenId: BigNumber
    fee: number
    liquidity: BigNumber
    tickLower: number
    tickUpper: number
  }
}
export interface V2ClaimRewardTransactionInfo {
  type: TransactionType.V2_CLAIM_REWARD
  data: any
}
export interface V2StakeLpTransactionInfo {
  type: TransactionType.V2_STAKE_LP
  data: any
}
export interface V2UnstakeLpTransactionInfo {
  type: TransactionType.V2_UNSTAKE_LP
  data: any
}

export interface LaunchDepositTransactionInfo {
  type: TransactionType.LAUNCH_DEPOSIT
  data: {
    detail: ProjectModel
    amount: string | number
  }
}
export interface LaunchClaimRefundTransactionInfo {
  type: TransactionType.LAUNCH_CLAIM_REFUND
  data: {
    detail: ProjectModel
    amount: string | number
  }
}
export interface LaunchClaimTokenTransactionInfo {
  type: TransactionType.LAUNCH_CLAIM_TOKEN
  data: {
    detail: ProjectModel
    amount: string | number
  }
}
export interface CreteTokenTransactionInfo {
  type: TransactionType.CREATE_TOKEN
  data: {
    detail: {
      name: string
      symbol: string
      maxSupply: number | bigint
      tradeBurnRatio: number | bigint
      tradeFeeRatio: number | bigint
    }
  }
}

export type TransactionInfo =
  | ApproveTransactionInfo
  | ExactOutputSwapTransactionInfo
  | ExactInputSwapTransactionInfo
  | ClaimTransactionInfo
  | VoteTransactionInfo
  | QueueTransactionInfo
  | ExecuteTransactionInfo
  | DelegateTransactionInfo
  | DepositLiquidityStakingTransactionInfo
  | WithdrawLiquidityStakingTransactionInfo
  | WrapTransactionInfo
  | CreateV3PoolTransactionInfo
  | AddLiquidityV3PoolTransactionInfo
  | AddLiquidityV2PoolTransactionInfo
  | MigrateV2LiquidityToV3TransactionInfo
  | CollectFeesTransactionInfo
  | RemoveLiquidityV3TransactionInfo
  | SubmitProposalTransactionInfo
  | SendTransactionInfo
  | ClaimRewardTransactionInfo
  | StakeLpTransactionInfo
  | UnstakeLpTransactionInfo
  | V2ClaimRewardTransactionInfo
  | V2StakeLpTransactionInfo
  | V2UnstakeLpTransactionInfo
  | LaunchDepositTransactionInfo
  | LaunchClaimRefundTransactionInfo
  | LaunchClaimTokenTransactionInfo
  | CreteTokenTransactionInfo

export interface TransactionDetails {
  hash: string
  receipt?: SerializableTransactionReceipt
  lastCheckedBlockNumber?: number
  addedTime: number
  confirmedTime?: number
  deadline?: number
  from: string
  info: TransactionInfo
  nonce?: number
  cancelled?: boolean
}
