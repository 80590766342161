import { Box, Button, Group, Modal, Slider, Stack, Text, createStyles } from '@asuikit/core'
import { CurrencyAmount } from '@uniswap/sdk-core'
import { Pair } from '@uniswap/v2-sdk'
import { useRequest } from 'ahooks'
import BigNumberJS from 'bignumber.js'
import JSBI from 'jsbi'
import { useMemo, useState } from 'react'
import { parseUnits } from 'viem'
import { DoubleCurrencyLogo } from '../../components/DoubleLogo'
import CurrencyLogo from '../../components/Logo/CurrencyLogo'
import { useLargeThan } from '../../hooks/detrade/useWidthQuery'
import { useTotalSupply } from '../../hooks/useTotalSupply'
import { mantissaNumber } from '../../utils/detrade/number'
import { handleErrorMessage } from '../../utils/handleErrorMessage'
import { didUserReject } from '../../utils/swapErrorToUserReadableMessage'
import { unwrappedToken } from '../../utils/unwrappedToken'

const useStyles = createStyles((theme) => {
  const colors = theme.colors
  return {
    btn: {
      padding: '6px 8px',
      borderRadius: 4,
      border: '1px solid' + colors.line[2],
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      colors: '#0B132A',
      cursor: 'pointer',
      '&.selected': {
        backgroundColor: '#3C38F5',
        borderColor: '#3C38F5',
        color: '#FFFFFF',
      },
    },
  }
})

export default function ModalV2PoolUnstake({
  data,
  pair,
  amount,
  opened,
  onClose,
  confirmUnstake,
}: {
  data: any
  pair?: Pair | null
  amount: string
  opened: boolean
  onClose: () => void
  confirmUnstake?: (stakeContractAddress: string, value: string, skipRefresh?: boolean) => void
}) {
  const smScreen = useLargeThan('sm')
  const mdScreen = useLargeThan('md')

  const { classes, cx } = useStyles()

  const [value, setValue] = useState<number>(50)
  const options = [
    {
      value: 25,
    },
    {
      value: 50,
    },
    {
      value: 75,
    },
    {
      value: 100,
    },
  ]

  const totalStakeToken =
    pair?.liquidityToken &&
    CurrencyAmount.fromRawAmount(pair?.liquidityToken as any, parseUnits(String(data.staked_amount), 18)?.toString?.())
  const totalPoolTokens = useTotalSupply(pair?.liquidityToken)

  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!totalStakeToken &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.quotient, totalStakeToken.quotient)
      ? [
          pair.getLiquidityValue(pair.token0, totalPoolTokens, totalStakeToken, false),
          pair.getLiquidityValue(pair.token1, totalPoolTokens, totalStakeToken, false),
        ]
      : [undefined, undefined]

  const token0Num = useMemo(() => {
    const num = new BigNumberJS(token0Deposited?.toExact() || '0').multipliedBy(value).div(100).toString()
    return mantissaNumber(num, 18)
  }, [token0Deposited, value])

  const token1Num = useMemo(() => {
    const num = new BigNumberJS(token1Deposited?.toExact() || '0').multipliedBy(value).div(100).toString()
    return mantissaNumber(num, 18)
  }, [token1Deposited, value])
  const currency0 = pair?.token0 && unwrappedToken(pair?.token0)
  const currency1 = pair?.token1 && unwrappedToken(pair?.token1)

  const { run: unstake, loading } = useRequest(
    async () => {
      try {
        const num = new BigNumberJS(amount || 0).multipliedBy(value).div(100).toString()
        await confirmUnstake?.(data.stake_contract, num)
        onClose()
      } catch (e: any) {
        if (!didUserReject(e)) {
          handleErrorMessage(e)
          console.log(e)
        }
      }
    },
    {
      manual: true,
    }
  )
  return (
    <>
      <Modal
        opened={opened}
        onClose={onClose}
        title="Unstaking"
        styles={({ colors }) => ({
          header: {
            padding: '20px 24px',
          },
          title: {
            fontWeight: 500,
            fontSize: 20,
            lineHeight: '24px',
          },
          body: {
            padding: '8px 24px 24px!important',
          },
        })}
      >
        <Stack spacing={24}>
          <Group spacing={8}>
            <DoubleCurrencyLogo currencies={[currency0, currency1]} size={30} />
            <Group spacing={10}>
              <Text fw={500} fz={20} lh="24px" color="#0B132A">
                {currency0?.symbol}/{currency1?.symbol}
              </Text>
            </Group>
          </Group>

          <Box
            sx={({ colors }) => ({
              background: '#F7F7F7',
              borderRadius: 8,
              padding: 16,
            })}
          >
            <Text fw={400} fz={14} lh="20px" color="#959AA4">
              Amount
            </Text>
            <Group mt={12} mb={22} position="apart">
              <Text fw={500} fz={32} lh="36px" color="#0B132A">
                {value}%
              </Text>
              <Group spacing={6}>
                {options.map((option, index) => (
                  <Group
                    position="center"
                    className={cx(classes.btn, {
                      selected: option.value === value,
                    })}
                    key={index}
                    onClick={() => setValue(option.value)}
                  >
                    {option.value}%
                  </Group>
                ))}
              </Group>
            </Group>
            <Slider
              value={value}
              onChange={(value) => setValue(value)}
              size={4}
              styles={({ colors }) => ({
                thumb: {
                  width: 20,
                  height: 20,
                  background: '#3C38F5',
                },
              })}
            />
          </Box>

          <Box
            sx={({ colors }) => ({
              background: '#F7F7F7',
              borderRadius: 8,
              padding: '18px 16px',
            })}
          >
            <Stack spacing={16}>
              <Group position="apart">
                <Text fw={500} fz={16} lh="20px" color="#0B132A">
                  {currency0?.symbol}
                </Text>
                <Group spacing={6}>
                  <CurrencyLogo currency={currency0} size={24} />
                  <Text fw={500} fz={16} lh="20px" color="#0B132A">
                    {token0Num}
                  </Text>
                </Group>
              </Group>
              <Group position="apart">
                <Text fw={500} fz={16} lh="20px" color="#0B132A">
                  {currency1?.symbol}
                </Text>
                <Group spacing={6}>
                  <CurrencyLogo currency={currency1} size={24} />
                  <Text fw={500} fz={16} lh="20px" color="#0B132A">
                    {token1Num}
                  </Text>
                </Group>
              </Group>
            </Stack>
          </Box>

          <Button fullWidth size="xl" loading={loading} onClick={unstake}>
            Unstake
          </Button>
        </Stack>
      </Modal>
    </>
  )
}
