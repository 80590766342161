import { BigNumber } from '@ethersproject/bignumber'
import { MaxUint256 } from '@ethersproject/constants'
import { NONFUNGIBLE_POSITION_MANAGER_ADDRESSES } from '@uniswap/sdk-core'
import NonfungiblePositionManagerJson from '@uniswap/v3-periphery/artifacts/contracts/NonfungiblePositionManager.sol/NonfungiblePositionManager.json'
import { useWeb3React } from '@web3-react/core'
import BigNumberJS from 'bignumber.js'
import { useCallback } from 'react'
import { Erc721 } from 'uniswap/src/abis/types'
import { getContract } from 'utilities/src/contracts/getContract'
import { parseUnits } from 'viem'
import { getApiChainParam } from '../../constants/chains'
import useCheckTargetChain from '../../hooks/useCheckTargetChain'
import { useContract, useTokenContract } from '../../hooks/useContract'
import { getPoolConfig, postMyPoolsClaimSign } from '../../service/pools.api'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { TransactionType } from '../../state/transactions/types'
import LPStakingPoolAbi from './LPStakingPoolAbi.json'
import stakePoolAbi from './stakePoolAbi.json'
import { stakePoolChainId } from './stakePoolConst'

export function useStakePool() {
  const { account, provider } = useWeb3React()
  const positionManager = NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[stakePoolChainId]
  const { abi: NFTPositionManagerABI } = NonfungiblePositionManagerJson
  const positionManagerContract = useContract<Erc721>(positionManager, NFTPositionManagerABI)

  const checkTargetChain = useCheckTargetChain()
  return useCallback(
    async (tokenId: number | BigNumber) => {
      checkTargetChain(stakePoolChainId)
      const config = await getPoolConfig()

      const contractAddress = config.stake_contract?.[stakePoolChainId]

      if (!positionManagerContract || !account || !provider) return
      const res = await positionManagerContract.isApprovedForAll(account, contractAddress)

      if (!res) {
        const res = await positionManagerContract.setApprovalForAll(contractAddress, true)
      }
      const stakeContract = getContract(contractAddress, stakePoolAbi, provider, account)
      // const estimatedGas = await stakeContract.estimateGas.deposit(positionManager, tokenId)
      const txRes = await stakeContract.deposit(positionManager, tokenId, {
        // gasLimit: calculateGasMargin(estimatedGas),
      })
      return txRes
    },
    [account, checkTargetChain, positionManager, positionManagerContract, provider]
  )
}

export function useUnStakePool() {
  const { account, provider } = useWeb3React()
  const positionManager = NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[stakePoolChainId]
  const { abi: NFTPositionManagerABI } = NonfungiblePositionManagerJson
  const positionManagerContract = useContract<Erc721>(positionManager, NFTPositionManagerABI)

  const checkTargetChain = useCheckTargetChain()
  return useCallback(
    async (tokenId: number | BigNumber) => {
      checkTargetChain(stakePoolChainId)
      const config = await getPoolConfig()

      const contractAddress = config.stake_contract?.[stakePoolChainId]

      if (!positionManagerContract || !account || !provider) return
      const res = await positionManagerContract.isApprovedForAll(account, contractAddress)

      if (!res) {
        const res = await positionManagerContract.setApprovalForAll(contractAddress, true)
      }
      const stakeContract = getContract(contractAddress, stakePoolAbi, provider, account)
      const txRes = await stakeContract.withdraw(positionManager, tokenId)
      return txRes
    },
    [account, checkTargetChain, positionManager, positionManagerContract, provider]
  )
}

export function useClaimReward() {
  const { account, provider } = useWeb3React()
  const positionManager = NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[stakePoolChainId]
  const { abi: NFTPositionManagerABI } = NonfungiblePositionManagerJson
  const positionManagerContract = useContract<Erc721>(positionManager, NFTPositionManagerABI)

  const checkTargetChain = useCheckTargetChain()
  return useCallback(
    async (tokenId: number | BigNumber, reward_token_address: string) => {
      checkTargetChain(stakePoolChainId)
      const config = await getPoolConfig()
      const contractAddress = config.stake_contract?.[stakePoolChainId]
      //
      if (!positionManagerContract || !account || !provider) return
      const signer = provider.getSigner(account)
      const params = {
        chain: getApiChainParam(stakePoolChainId),
        reward_address: account,
        timestamp: Date.now(),
        token_address: positionManager,
        reward_token_address,
        token_id: Number(tokenId.toString()),
      }
      const msg = Object.keys(params)
        .map((key, index) => {
          // @ts-ignore
          return `${key}: ${params?.[key]}`
        })
        .join('\n')
      const signature = await signer.signMessage(msg)
      const res = await postMyPoolsClaimSign({
        ...params,
        signature,
      })

      const stakeContract = getContract(contractAddress, stakePoolAbi, provider, account)
      const txRes = await stakeContract.claimReward(
        res.tokenAddress,
        res.tokenId,
        res.rewardTokenAddress,
        res.amount,
        res.totalAmount,
        res.to,
        res.expireTimestamp,
        res.signature
      )
      return txRes
    },
    [account, checkTargetChain, positionManager, positionManagerContract, provider]
  )
}

export function useStakeV2Pool(tokenAddress: string) {
  const { account, provider } = useWeb3React()
  const abi = LPStakingPoolAbi.abi
  const tokenContract = useTokenContract(tokenAddress)

  const checkTargetChain = useCheckTargetChain()
  const addTransaction = useTransactionAdder()
  return useCallback(
    async (contractAddress: string, amount: string) => {
      if (!contractAddress || !tokenContract || !account || !provider) return

      checkTargetChain(stakePoolChainId)
      const res = await tokenContract.allowance(account, contractAddress)
      const typedAmountParsed = parseUnits(amount, 18).toString()
      const amountObj = new BigNumberJS(typedAmountParsed)
      if (amountObj.isGreaterThan(new BigNumberJS(res.toString()))) {
        const response = await tokenContract.approve(contractAddress, MaxUint256)
        addTransaction(response, {
          type: TransactionType.APPROVAL,
          tokenAddress,
          spender: contractAddress,
          amount: typedAmountParsed,
        })
      }
      const stakeContract = getContract(contractAddress, abi, provider, account)
      const txRes = await stakeContract.stake(typedAmountParsed)
      return txRes
    },
    [abi, account, addTransaction, checkTargetChain, provider, tokenAddress, tokenContract]
  )
}

export function useUnstakeV2Pool() {
  const { account, provider } = useWeb3React()
  const abi = LPStakingPoolAbi.abi

  const checkTargetChain = useCheckTargetChain()
  const addTransaction = useTransactionAdder()
  return useCallback(
    async (contractAddress: string, amount: string) => {
      if (!contractAddress || !account || !provider) return

      checkTargetChain(stakePoolChainId)
      const stakeContract = getContract(contractAddress, abi, provider, account)

      const typedAmountParsed = parseUnits(amount, 18).toString()
      const txRes = await stakeContract.unstake(typedAmountParsed)
      return txRes
    },
    [abi, account, provider]
  )
}

export function useClaimV2Pool() {
  const { account, provider } = useWeb3React()
  const abi = LPStakingPoolAbi.abi

  const checkTargetChain = useCheckTargetChain()
  const addTransaction = useTransactionAdder()
  return useCallback(
    async (contractAddress: string) => {
      if (!contractAddress || !account || !provider) return
      checkTargetChain(stakePoolChainId)
      const stakeContract = getContract(contractAddress, abi, provider, account)
      const txRes = await stakeContract.claim()
      return txRes
    },
    [abi, account, provider]
  )
}
