import { Box, Group, Menu, Text, createStyles } from '@asuikit/core'
import { useWeb3React } from '@web3-react/core'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { RiArrowDownSLine, RiCheckLine } from 'react-icons/ri'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import useParsedQueryString from '../../hooks/useParsedQueryString'

const useStyles = createStyles((theme) => {
  const colors = theme.colors
  return {
    root: {},
  }
})

function PoolTypeSelector({ onChange }: { onChange: () => void }) {
  const { classes, cx } = useStyles()
  const { t, i18n } = useTranslation()

  const navigate = useNavigate()
  // 获取当前路由的路径
  const location = useLocation()
  const { search } = location

  const [_, setQuery] = useSearchParams()
  const query = useParsedQueryString()
  const { poolType } = query

  const { chainId } = useWeb3React()
  const options = [
    {
      path: `v2`,
      title: 'Full range (V2)',
    },
    {
      path: `v3`,
      title: 'Concentraded (V3)',
    },
  ]
  const selectedIndex = useMemo(() => {
    const index = poolType === 'v3' ? 1 : 0
    return index
  }, [poolType])

  return (
    <div className={classes.root}>
      <Menu
        width={270}
        styles={({ colors }) => ({
          dropdown: {
            background: '#fff',
            padding: '8px 10px',
            borderRadius: 8,
          },
          item: {
            padding: 0,
          },
        })}
      >
        <Menu.Target>
          <Box sx={{ display: 'inline-block' }}>
            <Group
              spacing={12}
              sx={({ colors }) => ({
                border: '1px solid' + colors.line[3],
                borderRadius: 8,
                padding: 10,
                cursor: 'pointer',
              })}
              noWrap
              position="apart"
            >
              <Text fw={400} fz={12} lh="16px" color="text.3">
                Pool Type
              </Text>
              <Group spacing={12}>
                <Text fw={500} fz={12} lh="16px" color="blue.9" sx={{ flexGrow: 1 }} ta="right">
                  {options[selectedIndex]?.title}
                </Text>
                <RiArrowDownSLine size={16} color="#B3B3B3" />
              </Group>
            </Group>
          </Box>
        </Menu.Target>

        <Menu.Dropdown>
          {options.map((option, index) => (
            <Menu.Item
              key={index}
              onClick={() => {
                onChange()
                setQuery({
                  ...query,
                  poolType: option.path,
                })
              }}
            >
              <Group
                position="apart"
                px={8}
                py={10}
                sx={({ colors }) => ({
                  borderRadius: 6,
                  backgroundColor: index === selectedIndex ? '#f7f7f7' : 'none',
                })}
              >
                {index === selectedIndex ? (
                  <Text fw={500} fz={14} lh="20px" color="grey.9">
                    <Text>{option.title}</Text>
                  </Text>
                ) : (
                  <Text fw={400} fz={14} lh="20px" color="grey.7">
                    <Text>{option.title}</Text>
                  </Text>
                )}

                {index === selectedIndex && <RiCheckLine size={16} color="#7257FF" />}
              </Group>
            </Menu.Item>
          ))}
        </Menu.Dropdown>
      </Menu>
    </div>
  )
}

export default PoolTypeSelector
