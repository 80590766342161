import { Box, Button, Grid, Group, Modal, Slider, Stack, Text, createStyles } from '@asuikit/core'
import { BigNumber } from '@ethersproject/bignumber'
import { Contract } from '@ethersproject/contracts'
import type { TransactionResponse } from '@ethersproject/providers'
import { LiquidityEventName } from '@uniswap/analytics-events'
import { Currency, Percent } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useTheme } from 'styled-components'
import { sendAnalyticsEvent, useTrace } from '../../../analytics'
import { WRAPPED_NATIVE_CURRENCY } from '../../../constants/tokens'
import { useCurrency } from '../../../hooks/Tokens'
import { ApprovalState, useApproveCallback } from '../../../hooks/useApproveCallback'
import { usePairContract, useV2RouterContract } from '../../../hooks/useContract'
import useDebouncedChangeHandler from '../../../hooks/useDebouncedChangeHandler'
import { useNetworkSupportsV2 } from '../../../hooks/useNetworkSupportsV2'
import { useGetTransactionDeadline } from '../../../hooks/useTransactionDeadline'
import { useV2LiquidityTokenPermit } from '../../../hooks/useV2LiquidityTokenPermit'
import { Trans } from '../../../i18n'
import { Field } from '../../../state/burn/actions'
import { useBurnActionHandlers, useBurnState, useDerivedBurnInfo } from '../../../state/burn/hooks'
import { useTransactionAdder } from '../../../state/transactions/hooks'
import { TransactionType } from '../../../state/transactions/types'
import { useUserSlippageToleranceWithDefault } from '../../../state/user/hooks'
import { calculateGasMargin } from '../../../utils/calculateGasMargin'
import { calculateSlippageAmount } from '../../../utils/calculateSlippageAmount'
import { currencyId } from '../../../utils/currencyId'
import { unwrappedToken } from '../../../utils/unwrappedToken'
import { useToggleAccountDrawer } from '../../AccountDrawer/MiniPortfolio/hooks'
import { ButtonPrimary } from '../../Button'
import { DoubleCurrencyLogo } from '../../DoubleLogo'
import CurrencyLogo from '../../Logo/CurrencyLogo'
import TransactionConfirmationModal, { ConfirmationModalContent } from '../../TransactionConfirmationModal'
import { Dots } from '../../swap/styled'

const DEFAULT_REMOVE_LIQUIDITY_SLIPPAGE_TOLERANCE = new Percent(50, 10_000)
const useStyles = createStyles((theme) => {
  const colors = theme.colors
  return {
    root: {},
    item: {
      border: '1px solid' + colors.line[2],
      height: 28,
      minWidth: 44,
      borderRadius: 4,
      color: '#0B132A',
      cursor: 'pointer',
      '&.selected': {
        color: '#FFFFFF',
        background: '#3C38F5',
      },
    },
    label: {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '20px',
      color: '#0B132A',
    },
    value: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '20px',
      color: '#4F5665',
    },
  }
})

function V2RemoveLiquidity({ currencyIdA, currencyIdB }: { currencyIdA?: string; currencyIdB?: string }) {
  const { classes, cx } = useStyles()
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const [currencyA, currencyB] = [useCurrency(currencyIdA) ?? undefined, useCurrency(currencyIdB) ?? undefined]
  const { account, chainId, provider } = useWeb3React()
  const [tokenA, tokenB] = useMemo(() => [currencyA?.wrapped, currencyB?.wrapped], [currencyA, currencyB])

  const theme = useTheme()
  const trace = useTrace()

  // toggle wallet when disconnected
  const toggleWalletDrawer = useToggleAccountDrawer()

  // burn state
  const { independentField, typedValue } = useBurnState()
  const { pair, parsedAmounts, error } = useDerivedBurnInfo(currencyA ?? undefined, currencyB ?? undefined)
  const { onUserInput: _onUserInput } = useBurnActionHandlers()
  const isValid = !error

  // modal and loading
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [showDetailed, setShowDetailed] = useState<boolean>(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false) // clicked confirm

  // txn values
  const [txHash, setTxHash] = useState<string>('')
  const getDeadline = useGetTransactionDeadline()
  const allowedSlippage = useUserSlippageToleranceWithDefault(DEFAULT_REMOVE_LIQUIDITY_SLIPPAGE_TOLERANCE)

  const formattedAmounts = {
    [Field.LIQUIDITY_PERCENT]: parsedAmounts[Field.LIQUIDITY_PERCENT].equalTo('0')
      ? '0'
      : parsedAmounts[Field.LIQUIDITY_PERCENT].lessThan(new Percent('1', '100'))
      ? '<1'
      : parsedAmounts[Field.LIQUIDITY_PERCENT].toFixed(0),
    [Field.LIQUIDITY]:
      independentField === Field.LIQUIDITY ? typedValue : parsedAmounts[Field.LIQUIDITY]?.toSignificant(6) ?? '',
    [Field.CURRENCY_A]:
      independentField === Field.CURRENCY_A ? typedValue : parsedAmounts[Field.CURRENCY_A]?.toSignificant(6) ?? '',
    [Field.CURRENCY_B]:
      independentField === Field.CURRENCY_B ? typedValue : parsedAmounts[Field.CURRENCY_B]?.toSignificant(6) ?? '',
  }

  const atMaxAmount = parsedAmounts[Field.LIQUIDITY_PERCENT]?.equalTo(new Percent('1'))

  // pair contract
  const pairContract: Contract | null = usePairContract(pair?.liquidityToken?.address)

  const router = useV2RouterContract()

  // allowance handling
  const { gatherPermitSignature, signatureData } = useV2LiquidityTokenPermit(
    parsedAmounts[Field.LIQUIDITY],
    router?.address
  )
  const [approval, approveCallback] = useApproveCallback(parsedAmounts[Field.LIQUIDITY], router?.address)

  async function onAttemptToApprove() {
    if (!pairContract || !pair || !provider) throw new Error('missing dependencies')
    const liquidityAmount = parsedAmounts[Field.LIQUIDITY]
    if (!liquidityAmount) throw new Error('missing liquidity amount')

    if (gatherPermitSignature) {
      try {
        await gatherPermitSignature()
      } catch (error) {
        // try to approve if gatherPermitSignature failed for any reason other than the user rejecting it
        if (error?.code !== 4001) {
          await approveCallback()
        }
      }
    } else {
      await approveCallback()
    }
  }

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      return _onUserInput(field, typedValue)
    },
    [_onUserInput]
  )

  const onLiquidityInput = useCallback(
    (typedValue: string): void => onUserInput(Field.LIQUIDITY, typedValue),
    [onUserInput]
  )
  const onCurrencyAInput = useCallback(
    (typedValue: string): void => onUserInput(Field.CURRENCY_A, typedValue),
    [onUserInput]
  )
  const onCurrencyBInput = useCallback(
    (typedValue: string): void => onUserInput(Field.CURRENCY_B, typedValue),
    [onUserInput]
  )

  // tx sending
  const addTransaction = useTransactionAdder()

  const networkSupportsV2 = useNetworkSupportsV2()

  async function onRemove() {
    if (!chainId || !provider || !account || !router || !networkSupportsV2) {
      throw new Error('missing dependencies')
    }
    const { [Field.CURRENCY_A]: currencyAmountA, [Field.CURRENCY_B]: currencyAmountB } = parsedAmounts
    if (!currencyAmountA || !currencyAmountB) {
      throw new Error('missing currency amounts')
    }

    const amountsMin = {
      [Field.CURRENCY_A]: calculateSlippageAmount(currencyAmountA, allowedSlippage)[0],
      [Field.CURRENCY_B]: calculateSlippageAmount(currencyAmountB, allowedSlippage)[0],
    }

    if (!currencyA || !currencyB) throw new Error('missing tokens')
    const liquidityAmount = parsedAmounts[Field.LIQUIDITY]
    if (!liquidityAmount) throw new Error('missing liquidity amount')

    const currencyBIsETH = currencyB.isNative
    const oneCurrencyIsETH = currencyA.isNative || currencyBIsETH

    if (!tokenA || !tokenB) throw new Error('could not wrap')

    const deadline = await getDeadline()
    if (!deadline) throw new Error('could not get deadline')

    let methodNames: string[], args: Array<string | string[] | number | boolean>
    // we have approval, use normal remove liquidity
    if (approval === ApprovalState.APPROVED) {
      // removeLiquidityETH
      if (oneCurrencyIsETH) {
        methodNames = ['removeLiquidityETH', 'removeLiquidityETHSupportingFeeOnTransferTokens']
        args = [
          currencyBIsETH ? tokenA.address : tokenB.address,
          liquidityAmount.quotient.toString(),
          amountsMin[currencyBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B].toString(),
          amountsMin[currencyBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A].toString(),
          account,
          deadline.toHexString(),
        ]
      }
      // removeLiquidity
      else {
        methodNames = ['removeLiquidity']
        args = [
          tokenA.address,
          tokenB.address,
          liquidityAmount.quotient.toString(),
          amountsMin[Field.CURRENCY_A].toString(),
          amountsMin[Field.CURRENCY_B].toString(),
          account,
          deadline.toHexString(),
        ]
      }
    }
    // we have a signature, use permit versions of remove liquidity
    else if (signatureData !== null) {
      // removeLiquidityETHWithPermit
      if (oneCurrencyIsETH) {
        methodNames = ['removeLiquidityETHWithPermit', 'removeLiquidityETHWithPermitSupportingFeeOnTransferTokens']
        args = [
          currencyBIsETH ? tokenA.address : tokenB.address,
          liquidityAmount.quotient.toString(),
          amountsMin[currencyBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B].toString(),
          amountsMin[currencyBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A].toString(),
          account,
          signatureData.deadline,
          false,
          signatureData.v,
          signatureData.r,
          signatureData.s,
        ]
      }
      // removeLiquidityETHWithPermit
      else {
        methodNames = ['removeLiquidityWithPermit']
        args = [
          tokenA.address,
          tokenB.address,
          liquidityAmount.quotient.toString(),
          amountsMin[Field.CURRENCY_A].toString(),
          amountsMin[Field.CURRENCY_B].toString(),
          account,
          signatureData.deadline,
          false,
          signatureData.v,
          signatureData.r,
          signatureData.s,
        ]
      }
    } else {
      throw new Error('Attempting to confirm without approval or a signature. Please contact support.')
    }

    const safeGasEstimates: (BigNumber | undefined)[] = await Promise.all(
      methodNames.map((methodName) =>
        router.estimateGas[methodName](...args)
          .then((estimateGas) => calculateGasMargin(estimateGas))
          .catch((error) => {
            console.error(`estimateGas failed`, methodName, args, error)
            return undefined
          })
      )
    )

    const indexOfSuccessfulEstimation = safeGasEstimates.findIndex((safeGasEstimate) =>
      BigNumber.isBigNumber(safeGasEstimate)
    )

    // all estimations failed...
    if (indexOfSuccessfulEstimation === -1) {
      console.error('This transaction would fail. Please contact support.')
    } else {
      const methodName = methodNames[indexOfSuccessfulEstimation]
      const safeGasEstimate = safeGasEstimates[indexOfSuccessfulEstimation]

      setAttemptingTxn(true)
      await router[methodName](...args, {
        gasLimit: safeGasEstimate,
      })
        .then((response: TransactionResponse) => {
          setAttemptingTxn(false)

          addTransaction(response, {
            type: TransactionType.REMOVE_LIQUIDITY_V3,
            baseCurrencyId: currencyId(currencyA),
            quoteCurrencyId: currencyId(currencyB),
            expectedAmountBaseRaw: parsedAmounts[Field.CURRENCY_A]?.quotient.toString() ?? '0',
            expectedAmountQuoteRaw: parsedAmounts[Field.CURRENCY_B]?.quotient.toString() ?? '0',
          })

          setTxHash(response.hash)

          sendAnalyticsEvent(LiquidityEventName.REMOVE_LIQUIDITY_SUBMITTED, {
            label: [currencyA.symbol, currencyB.symbol].join('/'),
            ...trace,
          })
        })
        .catch((error: Error) => {
          setAttemptingTxn(false)
          // we only care if the error is something _other_ than the user rejected the tx
          console.error(error)
        })
    }
  }

  function modalHeader() {
    return (
      <>
        <Box
          sx={({ colors }) => ({
            padding: '16px 16px',
            background: '#F7F7F7',
            borderRadius: 16,
          })}
        >
          <Stack spacing={14}>
            <Group position="apart">
              <div className={classes.label}>{currencyA?.symbol}</div>
              <div className={classes.value}>
                <Group spacing={6}>
                  <CurrencyLogo currency={currencyA} size={24} />
                  <Text fw={500} fz={32} lh="36px" color="#0B132A">
                    {parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)}
                  </Text>
                </Group>
              </div>
            </Group>
            <Text fw={500} fz={20} lh="24px" color="#959AA4">
              +
            </Text>
            <Group position="apart">
              <div className={classes.label}>{currencyB?.symbol}</div>
              <div className={classes.value}>
                <Group spacing={6}>
                  <CurrencyLogo currency={currencyB} size={24} />

                  <Text fw={500} fz={32} lh="36px" color="#0B132A">
                    {parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)}
                  </Text>
                </Group>
              </div>
            </Group>
          </Stack>
        </Box>
        <Text fw={400} fz={14} lh="18px" color="#FE9839" mt={12}>
          <Trans>
            Output is estimated. If the price changes by more than {{ allowed: allowedSlippage.toSignificant(4) }}% your
            transaction will revert.
          </Trans>
        </Text>
      </>
    )
  }

  function modalBottom() {
    return (
      <>
        <Stack spacing={24} pt={24}>
          <Box
            sx={({ colors }) => ({
              padding: '16px 16px',
              background: '#F7F7F7',
              borderRadius: 16,
            })}
          >
            <Stack spacing={14}>
              <Group position="apart">
                <div className={classes.label}>
                  {currencyA?.symbol}/{currencyB?.symbol} Burned
                </div>
                <div className={classes.value}>
                  <Group spacing={6}>
                    <DoubleCurrencyLogo currencies={[currencyA, currencyB]} size={24} />
                    <div className={classes.label}>{parsedAmounts[Field.LIQUIDITY]?.toSignificant(6)}</div>
                  </Group>
                </div>
              </Group>
              {pair && (
                <Group position="apart" align="top">
                  <div className={classes.label}>Price</div>
                  <div className={classes.value}>
                    <Stack spacing={6} align="flex-end">
                      <div>
                        1 {currencyA?.symbol} = {tokenA ? pair.priceOf(tokenA).toSignificant(6) : '-'}{' '}
                        {currencyB?.symbol}
                      </div>
                      <div>
                        1 {currencyB?.symbol} = {tokenB ? pair.priceOf(tokenB).toSignificant(6) : '-'}{' '}
                        {currencyA?.symbol}
                      </div>
                    </Stack>
                  </div>
                </Group>
              )}
            </Stack>
          </Box>

          <ButtonPrimary disabled={!(approval === ApprovalState.APPROVED || signatureData !== null)} onClick={onRemove}>
            <div data-fontWeight="$medium" data-fontSize={20}>
              <Trans>Confirm</Trans>
            </div>
          </ButtonPrimary>
        </Stack>
      </>
    )
  }

  const pendingText = (
    <Trans>
      Removing {{ amtA: parsedAmounts[Field.CURRENCY_A]?.toSignificant(6) }} {{ symA: currencyA?.symbol }} and
      {{ amtB: parsedAmounts[Field.CURRENCY_B]?.toSignificant(6) }} {{ symB: currencyB?.symbol }}
    </Trans>
  )

  const liquidityPercentChangeCallback = useCallback(
    (value: number) => {
      onUserInput(Field.LIQUIDITY_PERCENT, value.toString())
    },
    [onUserInput]
  )

  const oneCurrencyIsETH = currencyA?.isNative || currencyB?.isNative

  const oneCurrencyIsWETH = Boolean(
    chainId &&
      WRAPPED_NATIVE_CURRENCY[chainId] &&
      ((currencyA && WRAPPED_NATIVE_CURRENCY[chainId]?.equals(currencyA)) ||
        (currencyB && WRAPPED_NATIVE_CURRENCY[chainId]?.equals(currencyB)))
  )

  const handleSelectCurrencyA = useCallback(
    (currency: Currency) => {
      if (currencyIdB && currencyId(currency) === currencyIdB) {
        navigate(`/remove/v2/${currencyId(currency)}/${currencyIdA}`)
      } else {
        navigate(`/remove/v2/${currencyId(currency)}/${currencyIdB}`)
      }
    },
    [currencyIdA, currencyIdB, navigate]
  )
  const handleSelectCurrencyB = useCallback(
    (currency: Currency) => {
      if (currencyIdA && currencyId(currency) === currencyIdA) {
        navigate(`/remove/v2/${currencyIdB}/${currencyId(currency)}`)
      } else {
        navigate(`/remove/v2/${currencyIdA}/${currencyId(currency)}`)
      }
    },
    [currencyIdA, currencyIdB, navigate]
  )
  const [optionValue, setOptionValue] = useState(Number(formattedAmounts?.[Field.LIQUIDITY_PERCENT]))

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.LIQUIDITY_PERCENT, '0')
      setOptionValue(0)
    }
    setTxHash('')
  }, [onUserInput, txHash])

  const [innerLiquidityPercentage, setInnerLiquidityPercentage] = useDebouncedChangeHandler(
    Number.parseInt(parsedAmounts[Field.LIQUIDITY_PERCENT].toFixed(0)),
    liquidityPercentChangeCallback
  )

  const showUnwrapped = false
  const currency0 = showUnwrapped ? pair?.token0 : pair?.token0 && unwrappedToken(pair?.token0)
  const currency1 = showUnwrapped ? pair?.token1 : pair?.token1 && unwrappedToken(pair?.token1)

  const options = [
    {
      label: '25',
      value: '25',
    },
    {
      label: '50',
      value: '50',
    },
    {
      label: '75',
      value: '75',
    },
    {
      label: '100',
      value: '100',
    },
  ]

  useEffect(() => {
    return () => {
      setOptionValue(0)
      onUserInput(Field.LIQUIDITY_PERCENT, '0')
    }
  }, [])
  return (
    <div className={classes.root}>
      <Stack spacing={24} pt={8}>
        <Group spacing={8}>
          <DoubleCurrencyLogo currencies={[currencyA, currencyB]} size={30} />
          <Text fw={500} fz={20} lh="24px" color="#0B132A">
            {currency0?.symbol}/{currency1?.symbol}
          </Text>
        </Group>
        <Box
          sx={({ colors }) => ({
            padding: '16px 16px 24px',
            background: '#F7F7F7',
            borderRadius: 8,
          })}
        >
          <Text fw={400} fz={14} lh="20px" color="#959AA4">
            Amount
          </Text>
          <Group mt={12} mb={22} position="apart">
            <Text fw={500} fz={32} lh="36px" color="#0B132A">
              {formattedAmounts[Field.LIQUIDITY_PERCENT]}%
            </Text>
            <Group spacing={6}>
              {options.map((item, index) => (
                <Group
                  key={index}
                  className={cx(classes.item, {
                    selected: Number(formattedAmounts?.[Field.LIQUIDITY_PERCENT]) === Number(item.value),
                  })}
                  position="center"
                  onClick={() => {
                    setOptionValue(Number(item.value))
                    onUserInput(Field.LIQUIDITY_PERCENT, item.value)
                  }}
                >
                  <Text fw={400} fz={14} lh="16px">
                    {item.label}%
                  </Text>
                </Group>
              ))}
            </Group>
          </Group>
          <Slider
            value={optionValue}
            onChange={(val) => {
              setOptionValue(val)
              setInnerLiquidityPercentage(val)
            }}
            size={4}
            styles={({ colors }) => ({
              thumb: {
                borderWidth: 10,
              },
            })}
          />
        </Box>

        <Box
          sx={({ colors }) => ({
            padding: '16px 16px',
            background: '#F7F7F7',
            borderRadius: 8,
          })}
        >
          <Stack spacing={14}>
            <Group position="apart">
              <div className={classes.label}>{currencyA?.symbol}</div>
              <div className={classes.value}>
                <Group spacing={6}>
                  <CurrencyLogo currency={currencyA} size={24} />
                  <div className={classes.label}>{formattedAmounts[Field.CURRENCY_A] || '-'}</div>
                </Group>
              </div>
            </Group>
            <Group position="apart">
              <div className={classes.label}>{currencyB?.symbol}</div>
              <div className={classes.value}>
                <Group spacing={6}>
                  <CurrencyLogo currency={currencyB} size={24} />
                  <div className={classes.label}>{formattedAmounts[Field.CURRENCY_B] || '-'}</div>
                </Group>
              </div>
            </Group>
            <Group position="apart" align="top">
              <div className={classes.label}>Price</div>
              <div className={classes.value}>
                <Stack spacing={6} align="flex-end">
                  <div>
                    1 {currencyA?.symbol} = {tokenA ? pair?.priceOf(tokenA).toSignificant(6) : '-'} {currencyB?.symbol}
                  </div>
                  <div>
                    1 {currencyB?.symbol} = {tokenB ? pair?.priceOf(tokenB).toSignificant(6) : '-'} {currencyA?.symbol}
                  </div>
                </Stack>
              </div>
            </Group>
          </Stack>
        </Box>
        <Grid gutter={12}>
          <Grid.Col span={6}>
            <Button
              size="xl"
              fullWidth
              onClick={onAttemptToApprove}
              disabled={approval !== ApprovalState.NOT_APPROVED || signatureData !== null}
            >
              {approval === ApprovalState.PENDING ? (
                <Dots>
                  <Trans>Approving</Trans>
                </Dots>
              ) : approval === ApprovalState.APPROVED || signatureData !== null ? (
                <Trans>Approved</Trans>
              ) : (
                <Trans>Approve</Trans>
              )}
            </Button>
          </Grid.Col>
          <Grid.Col span={6}>
            <Button
              size="xl"
              fullWidth
              onClick={() => {
                setShowConfirm(true)
              }}
              color={error ? 'red' : ''}
              disabled={!isValid || (signatureData === null && approval !== ApprovalState.APPROVED)}
              sx={{ padding: 0 }}
            >
              {error || <Trans>Remove</Trans>}
            </Button>
          </Grid.Col>
        </Grid>
      </Stack>

      <TransactionConfirmationModal
        title="You will receive"
        isOpen={showConfirm}
        onDismiss={handleDismissConfirmation}
        attemptingTxn={attemptingTxn}
        hash={txHash ? txHash : ''}
        reviewContent={() => (
          <ConfirmationModalContent
            title=""
            onDismiss={handleDismissConfirmation}
            topContent={modalHeader}
            bottomContent={modalBottom}
          />
        )}
        pendingText={pendingText}
      />
    </div>
  )
}

function V2RemoveLiquidityModal({
  opened,
  onClose,
  currencyIdA,
  currencyIdB,
}: {
  opened: boolean
  onClose: () => void
  currencyIdA?: string
  currencyIdB?: string
}) {
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      size={480}
      title="Remove liquidity"
      centered
      styles={({ colors, fn }) => ({
        content: {
          background: colors.bg[1],
        },
        header: {
          background: colors.bg[1],
          height: 64,
          padding: '20px 24px',
          // borderBottom: '1px solid' + colors.line[2],
        },
        title: {
          fontWeight: 500,
          fontSize: 20,
          lineHeight: '24px',
          color: '#0B132A',
        },
        body: {
          padding: '24px 24px 24px',
        },
      })}
    >
      {opened && <V2RemoveLiquidity currencyIdA={currencyIdA} currencyIdB={currencyIdB} />}
    </Modal>
  )
}

export default V2RemoveLiquidityModal
