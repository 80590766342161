import { Box, Button, createStyles, Group, Modal, Slider, Stack, Text } from '@asuikit/core'
import { useDisclosure } from '@asuikit/hooks'
import { ChainId, Percent } from '@uniswap/sdk-core'
import { Pair } from '@uniswap/v2-sdk'
import { useWeb3React } from '@web3-react/core'
import { useRequest } from 'ahooks'
import BigNumberJS from 'bignumber.js'
import { useSupportedChainId } from 'constants/chains'
import JSBI from 'jsbi'
import { createContext, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { DoubleCurrencyLogo } from '../../components/DoubleLogo'
import CurrencyLogo from '../../components/Logo/CurrencyLogo'
import { useLargeThan } from '../../hooks/detrade/useWidthQuery'
import { useTotalSupply } from '../../hooks/useTotalSupply'
import { useTokenBalance } from '../../lib/hooks/useCurrencyBalance'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { TransactionInfo, TransactionType } from '../../state/transactions/types'
import { mantissaNumber } from '../../utils/detrade/number'
import { handleErrorMessage } from '../../utils/handleErrorMessage'
import { didUserReject } from '../../utils/swapErrorToUserReadableMessage'
import { unwrappedToken } from '../../utils/unwrappedToken'
import { useStakeV2Pool } from './stakeContract.api'

BigNumberJS.config({ EXPONENTIAL_AT: 1e9 })

type StakeV2PoolModalType = {
  opened: boolean
  close: () => void
  open: () => void
  pair?: Pair
  setPair: (pool: Pair) => void
  setContractAddress: (address: string) => void
}

export const StakeV2PoolModalContext = createContext<StakeV2PoolModalType>({
  opened: false,
  close: () => undefined,
  open: () => undefined,
  pair: undefined,
  setPair: (pair) => undefined,
  setContractAddress: (address) => undefined,
})

export function StakeV2PoolModalProvider({ children }: { children: React.ReactNode }) {
  const [opened, { open, close }] = useDisclosure(false)
  const [pair, setPair] = useState<Pair | undefined>(undefined)
  const [contractAddress, setContractAddress] = useState<string>('')
  useEffect(() => {
    if (!opened) {
      setPair(undefined)
      setContractAddress('')
    }
  }, [opened])
  return (
    <StakeV2PoolModalContext.Provider value={{ opened, open, close, pair, setPair, setContractAddress }}>
      {children}
      {pair && <StakeV2PoolModal opened={opened} pair={pair} onClose={close} contractAddress={contractAddress} />}
    </StakeV2PoolModalContext.Provider>
  )
}

const useStyles = createStyles((theme) => {
  const colors = theme.colors
  return {
    btn: {
      padding: '6px 8px',
      borderRadius: 4,
      border: '1px solid' + colors.line[2],
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      colors: '#0B132A',
      cursor: 'pointer',
      '&.selected': {
        backgroundColor: '#3C38F5',
        borderColor: '#3C38F5',
        color: '#FFFFFF',
      },
    },
  }
})

function StakeV2PoolModal({
  opened = false,
  pair,
  onClose,
  showUnwrapped = false,
  contractAddress = '',
}: {
  opened?: boolean
  pair: Pair
  onClose: () => void
  showUnwrapped?: boolean
  contractAddress: string
}) {
  const smScreen = useLargeThan('sm')
  const mdScreen = useLargeThan('md')
  const location = useLocation()

  const { account, chainId: connectedChainId, provider } = useWeb3React()
  const supportedChainId = useSupportedChainId(connectedChainId)
  const chainId = supportedChainId || ChainId.ALIENXCHAIN

  const currency0 = showUnwrapped ? pair.token0 : unwrappedToken(pair.token0)
  const currency1 = showUnwrapped ? pair.token1 : unwrappedToken(pair.token1)

  const userPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)

  const poolTokenPercentage =
    !!userPoolBalance &&
    !!totalPoolTokens &&
    JSBI.greaterThanOrEqual(totalPoolTokens.quotient, userPoolBalance.quotient)
      ? new Percent(userPoolBalance.quotient, totalPoolTokens.quotient)
      : undefined

  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.quotient, userPoolBalance.quotient)
      ? [
          pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
          pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false),
        ]
      : [undefined, undefined]

  const { classes, cx } = useStyles()

  const [value, setValue] = useState<number>(50)
  const options = [
    {
      value: 25,
    },
    {
      value: 50,
    },
    {
      value: 75,
    },
    {
      value: 100,
    },
  ]
  const stake = useStakeV2Pool(pair.liquidityToken.address)

  const addTransaction = useTransactionAdder()
  const { run: stakeToken, loading } = useRequest(
    async () => {
      try {
        const amount = new BigNumberJS(userPoolBalance ? userPoolBalance.toExact() : 0)
          .multipliedBy(value)
          .div(100)
          .valueOf()
        const response = await stake(contractAddress, amount)
        const transactionInfo: TransactionInfo = {
          type: TransactionType.V2_STAKE_LP,
          data: {
            token0: pair.token0.address,
            token1: pair.token1.address,
            amount,
          },
        }
        addTransaction(response, transactionInfo)
        onClose()
      } catch (e: any) {
        if (!didUserReject(e)) {
          handleErrorMessage(e)
          console.log(e)
        }
      }
    },
    {
      manual: true,
    }
  )
  const token0Num = useMemo(() => {
    const num = new BigNumberJS(token0Deposited?.toExact() || '0').multipliedBy(value).div(100).toString()
    return mantissaNumber(num, 18)
  }, [token0Deposited, value])

  const token1Num = useMemo(() => {
    const num = new BigNumberJS(token1Deposited?.toExact() || '0').multipliedBy(value).div(100).toString()
    return mantissaNumber(num, 18)
  }, [token1Deposited, value])

  return (
    <>
      <Modal
        opened={opened}
        onClose={onClose}
        title="Staking"
        styles={({ colors }) => ({
          header: {
            padding: '20px 24px',
          },
          title: {
            fontWeight: 500,
            fontSize: 20,
            lineHeight: '24px',
          },
          body: {
            padding: '8px 24px 24px!important',
          },
        })}
      >
        <Stack spacing={24}>
          <Group spacing={8}>
            <DoubleCurrencyLogo currencies={[currency0, currency1]} size={30} />
            <Group spacing={10}>
              <Text fw={500} fz={20} lh="24px" color="#0B132A">
                {currency0.symbol}/{currency1.symbol}
              </Text>
            </Group>
          </Group>

          <Box
            sx={({ colors }) => ({
              background: '#F7F7F7',
              borderRadius: 8,
              padding: 16,
            })}
          >
            <Text fw={400} fz={14} lh="20px" color="#959AA4">
              Amount
            </Text>
            <Group mt={12} mb={22} position="apart">
              <Text fw={500} fz={32} lh="36px" color="#0B132A">
                {value}%
              </Text>
              <Group spacing={6}>
                {options.map((option, index) => (
                  <Group
                    position="center"
                    className={cx(classes.btn, {
                      selected: option.value === value,
                    })}
                    key={index}
                    onClick={() => setValue(option.value)}
                  >
                    {option.value}%
                  </Group>
                ))}
              </Group>
            </Group>
            <Slider
              value={value}
              onChange={(value) => setValue(value)}
              size={4}
              styles={({ colors }) => ({
                thumb: {
                  width: 20,
                  height: 20,
                  background: '#3C38F5',
                },
              })}
            />
          </Box>

          <Box
            sx={({ colors }) => ({
              background: '#F7F7F7',
              borderRadius: 8,
              padding: '18px 16px',
            })}
          >
            <Stack spacing={16}>
              <Group position="apart">
                <Text fw={500} fz={16} lh="20px" color="#0B132A">
                  {currency0.symbol}
                </Text>
                <Group spacing={6}>
                  <CurrencyLogo currency={currency0} size={24} />
                  <Text fw={500} fz={16} lh="20px" color="#0B132A">
                    {token0Num}
                  </Text>
                </Group>
              </Group>
              <Group position="apart">
                <Text fw={500} fz={16} lh="20px" color="#0B132A">
                  {currency1.symbol}
                </Text>
                <Group spacing={6}>
                  <CurrencyLogo currency={currency1} size={24} />
                  <Text fw={500} fz={16} lh="20px" color="#0B132A">
                    {token1Num}
                  </Text>
                </Group>
              </Group>
            </Stack>
          </Box>

          <Button fullWidth size="xl" loading={loading} onClick={stakeToken}>
            Approve and stake
          </Button>
        </Stack>
      </Modal>
    </>
  )
}
